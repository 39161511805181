/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'existing_problems_with_heating__yes': {
    width: 60,
    height: 60,
    viewBox: '0 0 60 60',
    data: '<g clip-path="url(#clip0_303_21)" _fill="#000"><path pid="0" d="M25.688 24.014h.21a2.78 2.78 0 012.75 2.75 2.69 2.69 0 01-1.34 2.33 2.82 2.82 0 01-.91 5.34 2.7 2.7 0 01.87 2 3.773 3.773 0 01-.21 1 .9.9 0 01-.08.21c-1.21 1.95-3.67 4.58-3.91 7.41-.24 2.83 1.08 5.46 2.93 4.88 1.85-.58 2-2.38 2.75-4.13 1.21-2.83 4-4.12 6.29-6.41s2.21-4.3 5.54-4.3h5.9v-12.38h-7.75c-1.08 0-2.54-2.41-6-2.54-1.54 0-4.09-.08-6.75-.12a2.69 2.69 0 01-.08 3.8 1.145 1.145 0 01-.17.15l-.04.01z"/><path pid="1" d="M26.148 37.474c.137-.203.235-.43.29-.67.02-.136.02-.274 0-.41.02-.126.02-.254 0-.38a2.001 2.001 0 00-.29-.67 2.4 2.4 0 00-.84-.71 1.9 1.9 0 00-.75-.16h-4.87a1.93 1.93 0 00-1.38.54 1.89 1.89 0 00-.54 1.33 1.91 1.91 0 001.91 1.92h4.93c.26.006.516-.049.75-.16.313-.139.585-.356.79-.63zM26.098 33.644a1.7 1.7 0 00.8-.17 2.11 2.11 0 001.1-1.08c.101-.255.159-.525.17-.8a1.68 1.68 0 00-.17-.79 2.11 2.11 0 00-1.08-1.08 2.717 2.717 0 00-.8-.17h-8.54a2 2 0 00-2 2 2.08 2.08 0 002 2.09h8.52zM15.858 27.844c.212.303.498.547.83.71.24.097.493.154.75.17h8.46c.123.02.248.02.37 0a1.72 1.72 0 001-.54c.183-.178.327-.392.42-.63.094-.24.148-.493.16-.75a1.609 1.609 0 00-.16-.75 2.416 2.416 0 00-.42-.62 2 2 0 00-1-.55 1.547 1.547 0 00-.37 0h-8.46c-.26 0-.516.059-.75.17a1.94 1.94 0 00-1 2.57c.043.105.097.206.16.3l.01-.08zM19.398 20.604a1.9 1.9 0 000 2.69c.102.1.216.188.34.26l.26.13.38.13c.123.02.248.02.37 0h3.25a1 1 0 00.38 0 1.93 1.93 0 001.45-2.3 2 2 0 00-1.47-1.5 1.227 1.227 0 00-.36 0h-3.25a1.137 1.137 0 00-.37 0l-.38.12a1.36 1.36 0 00-.6.47z"/></g><defs><clipPath id="clip0_303_21"><path pid="2" _fill="#fff" d="M0 0h60v60H0z"/></clipPath></defs>'
  }
})
