/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_type__end_terraced_house': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" _fill="#666" d="M33.32 47.22H.89V50h58.2v-2.78H33.32z"/><g _fill="#666"><path pid="1" d="M29.93 27.49l8.15 6.77V46.3h-4.99v-7.69h-6.32v7.7h-4.85V34.14l.08-.07 2.28-1.89 5.64-4.69m0-.65l-5.96 4.96-2.28 1.89-.27.22v12.9h5.85v-7.7h5.32v7.69h5.99V34.02l-8.65-7.19z"/><path pid="2" d="M29.92 22.74l8.16 6.92v2.55l-7.83-6.65-.32-.27-.32.27-6.39 5.42-1.29 1.09v-2.54l8-6.79m0-.66l-8.5 7.21v3.86l2.11-1.79 6.39-5.42 8.66 7.35v-3.86l-8.66-7.35z"/></g><g _fill="#666"><path pid="3" d="M12.73 22.23L.99 32.32l1.61 2.42 10.13-8.71 6.21 5.34 2.04 1.75v-3.8l-8.25-7.09z"/><path pid="4" d="M20.73 33.69l-2.22-1.89-5.82-4.96-8.44 7.19v12.78h5.85v-7.69h5.18v7.69h5.69V33.89l-.25-.21zm-8.53 3.34H9.91v-2.29h2.29v2.29zm0-3.02H9.91v-2.29h2.29v2.29zm3.12 3.02h-2.29v-2.29h2.29v2.29zm0-3.02h-2.29v-2.29h2.29v2.29z"/></g><g _fill="#666"><path pid="5" d="M47.35 22.29l11.74 10.1-1.61 2.41-10.13-8.7-6.21 5.34-2.03 1.75v-3.8l8.24-7.1z"/><path pid="6" d="M39.35 33.69l2.22-1.89 5.82-4.96 8.44 7.19v12.78h-5.85v-7.69H44.8v7.69h-5.69V33.89l.25-.21zm8.53 3.34h2.29v-2.29h-2.29v2.29zm0-3.02h2.29v-2.29h-2.29v2.29zm-3.12 3.02h2.29v-2.29h-2.29v2.29zm0-3.02h2.29v-2.29h-2.29v2.29z"/></g>'
  }
})
