/**
 * Return the position of an element on the y-axis.
 * @param {('top'|'middle'|'bottom')} [measureTo=top] Where along the elements height
 * to calculate the y position.
 * @return The y positon calculated by element.getBoundingClientRect().
 */
const getElementYPosition = (el, measureTo = 'top') => {
    const boundingClientRect = el.getBoundingClientRect();
    if (measureTo === 'top') {
        return boundingClientRect.top;
    }

    if (measureTo === 'middle') {
        return (boundingClientRect.top + boundingClientRect.bottom) / 2;
    }

    if (measureTo === 'bottom') {
        return boundingClientRect.bottom;
    }
    // eslint-disable-next-line
    throw 'Parameter "measureTo" must be "top", "middle" or "bottom"';
};

// eslint-disable-next-line
export { getElementYPosition };
