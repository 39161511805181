/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_heated_area__301_400': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<g data-name="Gruppe 462"><g data-name="Gruppe 460"><path pid="0" data-name="Pfad 487" d="M46.59 15H12.5v35h35V15zm0 34.09H13.41V15.91h33.18z" _fill="#666"/></g><g data-name="Gruppe 461"><path pid="1" data-name="Rechteck 489" _fill="#666" d="M12.83 20.48h29.2v29.2h-29.2z"/></g></g>'
  }
})
