/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_type__apartment': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" _fill="#666" d="M11.55 47.22h36.24V50H11.55zM30 15.94l17 12.89 2.72-3.57L30 10.3 10.28 25.26 13 28.83l17-12.89zM39.75 35.11a.34.34 0 01-.3.18h-.88a.35.35 0 01-.31-.18l-.13-.26-1.06-.09v1.66a.21.21 0 00.21.22h3.48a.22.22 0 00.22-.22v-1.66l-1.09.09zM39.26 29a.55.55 0 00-.55.55v.56h-1.42a.21.21 0 00-.21.22v4.21H41v-4.21a.22.22 0 00-.22-.22h-1.43v-.46h4.46V29zM32.19 32.3h4V38h-4zM36.19 32c0-.33-.92-.6-2-.6s-1.95.27-2 .6v.1h4z"/><path pid="1" d="M30 17L15.87 27.71h28.26zm-.4 9.16h-2.42v-2.42h2.42zm0-3.17h-2.42v-2.42h2.42zm3.28 3.17h-2.42v-2.42h2.42zm0-3.17h-2.42v-2.42h2.42zM32.96 38.51H15.9v8.24h28.2v-8.24H32.96z" _fill="#666"/><path pid="2" _fill="#666" d="M16.36 27.7H15.9v18H16.4v-18h-.04zM44.06 27.71H43.6v11.38H44.1V27.71h-.04z"/>'
  }
})
