/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_type__multi_family_house': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" _fill="#666" d="M11.56 47.22H47.8V50H11.56zM30 10.68l17.01 12.9L49.72 20 30 5.04 10.28 20l2.71 3.58L30 10.68z"/><path pid="1" d="M44.13 46.75V38h-3.47v.46h1.41a.22.22 0 01.22.22v4.21h-3.9v-4.26a.21.21 0 01.21-.22H40v-.56a.56.56 0 01.56-.55h3.56V22.46L30 11.74 15.88 22.46v24.29H27v-9.23h5v9.23zm-5.33-24h2.42v2.42H38.8zm0 3.17h2.42v2.42H38.8zm0 4h2.42v2.42H38.8zm0 3.18h2.42v2.41H38.8zM21.17 43h-2.42v-2.39h2.42zm0-3.17h-2.42v-2.4h2.42zm0-4.39h-2.42v-2.39h2.42zm0-3.17h-2.42v-2.4h2.42zm0-4h-2.42v-2.38h2.42zm0-3.17h-2.42v-2.38h2.42zM24.46 43h-2.41v-2.39h2.41zm0-3.17h-2.41v-2.4h2.41zm0-4.39h-2.41v-2.39h2.41zm0-3.17h-2.41v-2.4h2.41zm0-4h-2.41v-2.38h2.41zm0-3.17h-2.41v-2.38h2.41zm5.13 10.32h-2.41v-2.37h2.41zm0-3.17h-2.41v-2.38h2.41zm0-4h-2.41v-2.36h2.41zm0-3.17h-2.41v-2.36h2.41zM38.38 43l1.06.09.14.26a.34.34 0 00.3.18h.89a.35.35 0 00.3-.18l.13-.26 1.09-.09v1.66a.22.22 0 01-.22.22H38.6a.22.22 0 01-.22-.22zm-2.87-20.28h2.42v2.42h-2.42zm0 3.17h2.42v2.42h-2.42zm0 4h2.42v2.42h-2.42zm0 3.18h2.42v2.41h-2.42zm-2 7.25c0-.33.91-.6 2-.6s2 .27 2 .6v.1h-4zm0 .3h4v5.7h-4zm-3-17.88h2.41v2.42h-2.45zm0 3.17h2.41v2.42h-2.45zm0 4h2.41v2.42h-2.45zm0 5.59v-2.45h2.41v2.41z" _fill="#666"/>'
  }
})
