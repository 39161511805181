/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'planned_installation_time__no_installation': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<g data-name="Gruppe 613"><g data-name="Gruppe 532"><g data-name="Gruppe 526"><g data-name="Gruppe 396" _fill="#666"><path pid="0" data-name="Pfad 444" d="M45.82 15.35a2.32 2.32 0 00-2.24-1.83H40v3.66A1.79 1.79 0 0138.18 19a1.75 1.75 0 01-1.73-1.78v-3.7H21.18v3.66A1.79 1.79 0 0119.39 19a1.75 1.75 0 01-1.78-1.73v-3.75h-3.56a2.26 2.26 0 00-2.29 2.24v29.58a2.3 2.3 0 001.83 2.24A4.14 4.14 0 0017.34 50h26.79a4.1 4.1 0 004.11-4.08V19.1a4 4 0 00-2.42-3.75zm-33.15 30V23.53H45v21.81a1.35 1.35 0 01-1.32 1.37H14.05a1.39 1.39 0 01-1.38-1.37zm34.7.55a3.21 3.21 0 01-3.2 3.2H17.38a3.14 3.14 0 01-2.69-1.51h28.93a2.27 2.27 0 002.29-2.25V16.4a3.25 3.25 0 011.51 2.7v26.79z"/><path pid="1" data-name="Pfad 445" d="M38.18 10a.87.87 0 00-.87.87v6.31a.87.87 0 00.87.87.84.84 0 00.87-.82v-6.36a.87.87 0 00-.87-.87z"/><path pid="2" data-name="Pfad 446" d="M19.39 10a.87.87 0 00-.86.87v6.31a.86.86 0 00.86.87.84.84 0 00.87-.82v-6.36a.86.86 0 00-.86-.87z"/></g></g></g><g data-name="Gruppe 534"><g data-name="Gruppe 533" _fill="#666"><path pid="3" data-name="Rechteck 571" d="M21.706 40.88l12.997-12.997 1.457 1.457-12.997 12.997z"/><path pid="4" data-name="Rechteck 572" d="M21.697 29.44l1.457-1.456L36.15 40.981l-1.456 1.456z"/></g></g></g>'
  }
})
