import _ from 'lodash';
import { sentryClient } from '@/utils/sentryClient';
import appConfig from '../config/app.json';
import { isValidToken } from './validators';
import {
    getStepsStepKeys,
    getRouteFromStepKey,
} from './appConfigGetters';

/**
 * Returns the query string of a window's url.
 * Example:
 *  if the window's url is 'https://example.de?key1=value1&key2=value2' then
 *  getUrlParamsString() -> '?key1=value1&key2=value2'
 * @param {Object} [otherWindow=false]
 * @returns {string}
 */
const getUrlQueryString = (otherWindow = false) => {
    const urlWindow = otherWindow || window;
    return urlWindow.location.search;
};

/**
 * Helper decoding a url-encoded string.
 * @param str
 * @returns {str}
 */
const decode = str => window.decodeURIComponent(str);

/**
 * Helper turning a key=value pair into an object.
 * @param param
 * @returns {{paramKey: *, paramValue: *}}
 */
const paramToObject = (param) => {
    const paramKey = decode(param.split('=')[0]);
    const paramValue = decode(param.split('=')[1]);

    return {
        paramKey,
        paramValue,
    };
};

/**
 * Helper turning an url params sequence into an array of key=value pairs objects.
 * @param urlParams
 * @returns {array}
 */
const getUrlParams = (urlParams) => {
    const output = [];
    const parsedToStrings = urlParams.replace(/^\?/, '').split('&');

    parsedToStrings.forEach((pair) => {
        output.push(paramToObject(pair));
    });

    return output;
};

/**
 * Returns true if the param key is valid. Valid means that it fits the app config file.
 * If a wrong key is given, it will be ignored.
 * If a correct key is given, but the value is not an existing choice, it will be ignored.
 * @param {Object} keys
 * @returns {Array}
 */
const isValidStepParam = (keys, param) => {
    if (
        _.includes(
            keys,
            param.paramKey,
        )
    ) {
        const stepRoute = getRouteFromStepKey(param.paramKey);

        if (
            _.some(
                appConfig.steps[stepRoute].choices,
                { value: param.paramValue },
            )
        ) {
            return true;
        }
        sentryClient.captureMessage('Valid question but wrong choice passed to the URL params', {
            level: 'warning',
            extra: {
                choice: param.paramValue,
            },
        });
    }
    return false;
};

/**
 * Returns the valid step params and a token if it is valid otherwise undefined.
 * @param {string} urlQueryString - Query string of the current window's url.
 * @returns {[{string: string}, string|undefined]}
 */
const extractQueryStringKnownKeys = (urlQueryString) => {
    const urlParams = getUrlParams(urlQueryString);
    const stepKeys = getStepsStepKeys();
    const stepParams = [];
    let token = false;

    urlParams.forEach((param) => {
        const { paramKey, paramValue } = param;

        if (isValidStepParam(stepKeys, param)) {
            const pair = {};
            pair[paramKey] = paramValue;
            stepParams.push(pair);
        } else if (paramKey === 'token') {
            if (isValidToken(paramValue)) {
                token = paramValue;
            } else {
                sentryClient.captureMessage('Invalid token provided in query-string', {
                    level: 'warning',
                    extra: {
                        token,
                    },
                });
            }
        }
    });

    return [stepParams, token];
};

export {
    getUrlQueryString,
    extractQueryStringKnownKeys,
};
