/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'energy_source_new__oil': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M42.2 34.78a29.53 29.53 0 00-3.56-7c-1.89-3-3.9-5.93-5.71-9a22.61 22.61 0 01-2.4-5.61A16 16 0 0130 10a16 16 0 01-.53 3.14 22.38 22.38 0 01-2.4 5.6c-1.81 3.12-3.81 6-5.71 9.05a29.53 29.53 0 00-3.56 7c-2 6.47 2.32 12.54 6.91 14.31A14.34 14.34 0 0030 50a14.37 14.37 0 005.27-.91c4.61-1.77 8.93-7.84 6.93-14.31zM21.8 45.13a8 8 0 01-.74-.69c-2.21-2.15-2.21-5.16-1.44-7.74a6 6 0 002.58 3.83v1.67a9.19 9.19 0 00.8 2.9 3.52 3.52 0 00.52 1 5.39 5.39 0 01-1.72-.97zm11.94 2.14s5.68-5.7 6-13.28c.94 6.47-.42 11.54-6 13.28z" _fill="#666"/>'
  }
})
