<template>
    <div class="leads-counter" v-if="digits">
        <p class="leads-counter__text">
            Anfragen heute
        </p>
        <div class="leads-counter__digit-wrapper"
             v-for="digit in digits"
             :key="digit.id">
            <span class="leads-counter__digit">{{ digit }}</span>
        </div>
    </div>
</template>

<script>
import { sentryClient } from '@/utils/sentryClient';

const getDigits = (integer, minDigitsN) => {
    if (typeof integer !== 'number' || integer % 1 !== 0) {
        sentryClient.captureMessage('(getDigits) Value is not an integer', {
            level: 'error',
            extra: {
                value: integer,
            },
        });
        return false;
    }
    const nonPaddedDigits = integer.toString();
    if (nonPaddedDigits.length > minDigitsN) {
        return nonPaddedDigits;
    }
    return `${'0'.repeat(minDigitsN - nonPaddedDigits.length)}${nonPaddedDigits}`;
};

export default {
    computed: {
        digits() {
            return getDigits(this.$store.state.requests.statistics.amountOfLeadsToday, 3);
        },
    },
};
</script>
