/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'energy_source_new__other': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M17.5 47.51a2.38 2.38 0 01.19-1 2.43 2.43 0 01.5-.78 2.5 2.5 0 01.78-.53 2.34 2.34 0 011-.19 2.38 2.38 0 011 .19 2.52 2.52 0 011.31 1.31 2.43 2.43 0 010 1.93 2.58 2.58 0 01-.53.77 2.48 2.48 0 01-.78.52 2.56 2.56 0 01-1 .18 2.51 2.51 0 01-1-.18 2.3 2.3 0 01-1.28-1.29 2.4 2.4 0 01-.19-.93zm10.05 0a2.56 2.56 0 01.18-1 2.63 2.63 0 01.51-.78 2.46 2.46 0 01.77-.53 2.52 2.52 0 011.92 0 2.52 2.52 0 011.31 1.31 2.55 2.55 0 010 1.93 2.58 2.58 0 01-.53.77 2.48 2.48 0 01-.78.52 2.65 2.65 0 01-1.92 0 2.43 2.43 0 01-.77-.52 2.72 2.72 0 01-.51-.77 2.58 2.58 0 01-.18-.93zm10.06 0a2.57 2.57 0 01.19-1 2.29 2.29 0 01.51-.78 2.35 2.35 0 01.77-.53 2.38 2.38 0 011-.19 2.34 2.34 0 011 .19 2.55 2.55 0 01.79.53 2.48 2.48 0 01.52.78 2.43 2.43 0 010 1.93 2.55 2.55 0 01-.52.77A2.44 2.44 0 0140 50a2.56 2.56 0 01-1-.18 2.33 2.33 0 01-.77-.52 2.35 2.35 0 01-.51-.77 2.58 2.58 0 01-.11-1.02z" _fill="#666"/>'
  }
})
