/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'energy_source_new__unsure': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M19.15 22.06a11.15 11.15 0 012.34-3.72 10.56 10.56 0 013.62-2.46 12.26 12.26 0 014.75-.88 14.93 14.93 0 015.65.93 11.1 11.1 0 013.65 2.31 8.41 8.41 0 012 3 8.54 8.54 0 01.6 3 10.13 10.13 0 01-.6 3.77 9.17 9.17 0 01-3.42 4.31c-.72.5-1.4 1-2 1.48a9.43 9.43 0 00-1.7 1.7 4.89 4.89 0 00-.93 2.38v1.81h-6.49v-2.18a9.8 9.8 0 01.78-3.43 10 10 0 011.5-2.37A12.93 12.93 0 0130.71 30c.64-.48 1.23-.95 1.77-1.43A6.4 6.4 0 0033.79 27a3.88 3.88 0 00.46-2.15 4.34 4.34 0 00-1.08-3.24 4.07 4.07 0 00-3-1.05 4.66 4.66 0 00-2.22.5 4.36 4.36 0 00-1.52 1.34 5.63 5.63 0 00-.89 1.95 10.28 10.28 0 00-.28 2.41h-7a13.71 13.71 0 01.88-4.72M33.43 46.26a3.74 3.74 0 11-3.74-3.75 3.74 3.74 0 013.74 3.75" _fill="#666"/>'
  }
})
