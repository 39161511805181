/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pv_system_existing_or_planned__already_installed': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<g _fill="#656666"><path pid="0" d="M52.66 22.86h-2.89v-1.71h2.89c.46 0 .84-.38.84-.84s-.38-.84-.84-.84h-2.89v-1.66h-4.34c-1.7 0-3.11 1.45-3.44 3.36h-4.23l-1.26-2.56H25.96l3.82 7.74h10.54l-1.72-3.49H42c.33 1.92 1.75 3.36 3.44 3.36h4.34v-1.66h2.89c.46 0 .84-.38.84-.84s-.38-.84-.84-.84zm-4.57 1.66h-2.66c-.99 0-1.83-1.15-1.83-2.52s.84-2.52 1.83-2.52h2.66v5.04zM26.88 26.34l-3.81-7.74H12.52l3.82 7.74h10.54zM25.59 37.26l-2.58 2.59L25.55 45H36.1l-3.82-7.74h-6.69zM22.63 28.16h-5.39l1.78 3.61 1.85-1.85 1.76-1.76zM26.9 31.71l2.13 2.12-1.62 1.61h3.97l-3.6-7.28h-4.44l1.77 1.76 1.79 1.79zM44.8 35.44l-3.59-7.28H30.67l3.59 7.28H44.8zM35.16 37.26L38.98 45h10.54l-3.82-7.74H35.16z"/></g><path pid="1" data-name="Pfad 7196" d="M22.99 32.04l-9.37 9.37L8.3 36.1l-1.79 1.79L13.63 45 24.8 33.83 23 32.04z" _fill="#656666"/>'
  }
})
