import collectedData from './collectedData';
import requests from './requests';
import settings from './settings';
import status from './status';
import ui from './ui';

export default {
    collectedData,
    requests,
    settings,
    status,
    ui,
};
