/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_heated_area__151_200': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<g data-name="Gruppe 456"><g data-name="Gruppe 454"><path pid="0" data-name="Pfad 485" d="M46.59 15H12.5v35h35V15zm0 34.09H13.41V15.91h33.18z" _fill="#666"/></g><g data-name="Gruppe 455"><path pid="1" data-name="Rechteck 487" _fill="#666" d="M12.83 28.43h21.25v21.25H12.83z"/></g></g>'
  }
})
