/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_heated_area__0_100': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<g data-name="Gruppe 464"><g data-name="Gruppe 463"><g data-name="Gruppe 452"><path pid="0" data-name="Pfad 483" d="M46.59 15H12.5v35h35V15zm0 34.09H26.66V35.84H13.41V15.91h33.18z" _fill="#666"/></g></g></g>'
  }
})
