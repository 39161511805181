/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'old_heating_energy_consumption__26000_30000': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M30 52.93C17.36 52.93 7.07 42.64 7.07 30S17.35 7.07 30 7.07 52.93 17.36 52.93 30 42.64 52.93 30 52.93zm0-43.38c-11.27 0-20.44 9.17-20.44 20.44S18.73 50.43 30 50.43s20.44-9.17 20.44-20.44S41.27 9.55 30 9.55z" _fill="#666"/><path pid="1" d="M44.6 21.47c1.37 2.33 2.15 4.94 2.29 7.66l-3.83-.12c-.14-1.87-.69-3.68-1.6-5.33l3.13-2.2m.29-1.42l-4.76 3.35a11.96 11.96 0 011.97 6.58l5.81.19v-.16c0-3.68-1.12-7.11-3.03-9.96zM42.97 31.85l3.82.12c-.32 2.73-1.29 5.32-2.86 7.59l-.63-.49-2.38-1.85a13.05 13.05 0 002.05-5.37m-.9-1.03c-.17 2.48-1.08 4.75-2.53 6.6l3.14 2.44 1.44 1.12a17.84 17.84 0 003.75-9.97l-5.8-.19zM36.52 14.41c2.49 1.04 4.68 2.66 6.42 4.74l-3.12 2.2a13.108 13.108 0 00-4.47-3.3l1.17-3.64m-.63-1.31l-1.78 5.53c2.21.8 4.12 2.23 5.52 4.07l4.75-3.34a17.945 17.945 0 00-8.49-6.26zM30 13.08c1.28 0 2.55.15 3.81.44l-1.17 3.64c-.88-.18-1.76-.28-2.64-.28s-1.76.09-2.64.28l-1.17-3.64c1.26-.29 2.54-.44 3.81-.44m0-1c-1.77 0-3.47.27-5.09.75l1.78 5.53c1.05-.3 2.16-.47 3.3-.47s2.25.17 3.3.47l1.78-5.53c-1.61-.48-3.32-.75-5.09-.75zM19.87 23.39l-4.76-3.35A17.82 17.82 0 0012.08 30v.16l5.81-.19c0-2.43.73-4.68 1.97-6.58zM12.13 31.01a17.74 17.74 0 003.75 9.97l1.44-1.12 3.14-2.44a12.062 12.062 0 01-2.53-6.6l-5.8.19zM19.52 19.1c-.64-.92-.45-2.19.44-2.84.91-.66 2.2-.45 2.87.47l1.97 2.36c.35-.17.71-.33 1.09-.46l-1.78-5.53c-3.42 1.2-6.37 3.4-8.49 6.26l4.75 3.34c.26-.34.54-.66.83-.98l-1.68-2.63z" _fill="#666"/><path pid="2" d="M32.8 27.97a3.422 3.422 0 00-3.18-1.37l-7.79-9.13c-.27-.37-.77-.46-1.12-.2-.35.26-.41.76-.14 1.13s6.35 10.18 6.35 10.18c-.5 1.09-.42 2.41.34 3.45a3.438 3.438 0 004.81.74 3.438 3.438 0 00.74-4.81zm-1.83 3.33c-.71.52-1.72.37-2.24-.35-.52-.71-.37-1.72.35-2.24.71-.52 1.72-.37 2.24.35.52.71.37 1.72-.35 2.24z" _fill="#666"/>'
  }
})
