/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'old_heating_energy_consumption__more_than_45000': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M30 52.93C17.36 52.93 7.07 42.64 7.07 30S17.35 7.07 30 7.07 52.93 17.36 52.93 30 42.64 52.93 30 52.93zm0-43.38c-11.27 0-20.44 9.17-20.44 20.44S18.73 50.43 30 50.43s20.44-9.17 20.44-20.44S41.27 9.55 30 9.55z" _fill="#666"/><g _fill="#666"><path pid="1" d="M19.87 23.39l-4.76-3.35A17.82 17.82 0 0012.08 30v.16l5.81-.19c0-2.43.73-4.68 1.97-6.58zM12.13 31.01a17.74 17.74 0 003.75 9.97l1.44-1.12 3.14-2.44a12.062 12.062 0 01-2.53-6.6l-5.8.19zM44.89 20.04l-4.76 3.35a11.96 11.96 0 011.97 6.58l5.81.19V30c0-3.68-1.12-7.11-3.03-9.96zM35.89 13.1l-1.78 5.53c2.21.8 4.12 2.23 5.52 4.07l4.75-3.34a17.993 17.993 0 00-8.49-6.26zM30 12.08c-1.77 0-3.47.27-5.09.75l1.78 5.53c1.05-.3 2.16-.47 3.3-.47s2.25.17 3.3.47l1.78-5.53c-1.61-.48-3.32-.75-5.09-.75zM15.62 19.36l4.75 3.34c1.4-1.84 3.31-3.27 5.52-4.07l-1.78-5.53c-3.42 1.2-6.37 3.4-8.49 6.26z"/></g><path pid="2" d="M42.07 30.82c-.02.36-.07.72-.13 1.07l2.9 1.24c1.01.36 1.58 1.53 1.2 2.59-.29.83-1.08 1.34-1.93 1.34-.22 0-.44-.03-.66-.11l-3.01-.86c-.27.46-.57.91-.9 1.33l3.14 2.44 1.44 1.12c2.17-2.79 3.53-6.23 3.75-9.97l-5.8-.19z" _fill="#666"/><path pid="3" d="M44.35 34.28l-10.98-4.86a3.42 3.42 0 00-2.22-2.66c-1.79-.64-3.76.3-4.4 2.09-.64 1.79.3 3.76 2.09 4.4 1.21.43 2.5.13 3.4-.66l11.57 3.18c.43.15.9-.05 1.04-.46.15-.41-.09-.86-.52-1.02zm-12.84-3.74c-.3.83-1.21 1.27-2.05.97s-1.27-1.21-.97-2.05a1.604 1.604 0 013.02 1.08z" _fill="#666"/>'
  }
})
