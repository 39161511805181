/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'existing_problems_with_heating__no': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<g data-name="Gruppe 569" _fill="#666"><path pid="0" data-name="Pfad 673" d="M36.31 46h-.21a2.78 2.78 0 01-2.75-2.75 2.69 2.69 0 011.34-2.33 2.82 2.82 0 01.91-5.34 2.7 2.7 0 01-.87-2 3.77 3.77 0 01.21-1 .9.9 0 01.08-.21c1.21-1.95 3.67-4.58 3.91-7.41S37.85 19.5 36 20.08s-2 2.38-2.75 4.13c-1.21 2.83-4 4.12-6.29 6.41s-2.21 4.3-5.54 4.3h-5.9V47.3h7.75c1.08 0 2.54 2.41 6 2.54 1.54 0 4.09.08 6.75.12a2.69 2.69 0 01.08-3.8 1.14 1.14 0 01.17-.15z"/><path pid="1" data-name="Pfad 674" d="M35.85 32.54a2 2 0 00-.29.67 1.39 1.39 0 000 .41 1.2 1.2 0 000 .38 2 2 0 00.29.67 2.4 2.4 0 00.84.71 1.9 1.9 0 00.75.16h4.87a1.93 1.93 0 001.38-.54 1.89 1.89 0 00.54-1.33 1.91 1.91 0 00-1.91-1.92h-4.93a1.66 1.66 0 00-.75.16 2 2 0 00-.79.63z"/><path pid="2" data-name="Pfad 675" d="M35.9 36.37a1.7 1.7 0 00-.8.17 2.11 2.11 0 00-1.1 1.08 2.44 2.44 0 00-.17.8 1.68 1.68 0 00.17.79 2.11 2.11 0 001.08 1.08 2.72 2.72 0 00.8.17h8.54a2 2 0 002-2 2.08 2.08 0 00-2-2.09H35.9z"/><path pid="3" data-name="Pfad 676" d="M46.14 42.17a2.19 2.19 0 00-.83-.71 2.41 2.41 0 00-.75-.17H36.1a1.17 1.17 0 00-.37 0 1.72 1.72 0 00-1 .54 1.79 1.79 0 00-.42.63 2.39 2.39 0 00-.16.75 1.61 1.61 0 00.16.75 2.42 2.42 0 00.42.62 2 2 0 001 .55 1.55 1.55 0 00.37 0h8.46a1.75 1.75 0 00.75-.17 1.94 1.94 0 001-2.57 1.73 1.73 0 00-.16-.3z"/><path pid="4" data-name="Pfad 677" d="M42.6 49.41a1.9 1.9 0 000-2.69 1.7 1.7 0 00-.34-.26l-.26-.13-.38-.13a1.16 1.16 0 00-.37 0H38a1 1 0 00-.38 0 1.93 1.93 0 00-1.45 2.3 2 2 0 001.47 1.5 1.23 1.23 0 00.36 0h3.25a1.14 1.14 0 00.37 0l.38-.12a1.36 1.36 0 00.6-.47z"/></g>'
  }
})
