/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'old_heating_energy_consumption__31000_35000': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M30 52.93C17.36 52.93 7.07 42.64 7.07 30S17.35 7.07 30 7.07 52.93 17.36 52.93 30 42.64 52.93 30 52.93zm0-43.38c-11.27 0-20.44 9.17-20.44 20.44S18.73 50.43 30 50.43s20.44-9.17 20.44-20.44S41.27 9.55 30 9.55z" _fill="#666"/><path pid="1" d="M44.6 21.47c1.37 2.33 2.15 4.94 2.29 7.66l-3.83-.12c-.14-1.87-.69-3.68-1.6-5.33l3.13-2.2m.29-1.42l-4.76 3.35a11.96 11.96 0 011.97 6.58l5.81.19v-.16c0-3.68-1.12-7.11-3.03-9.96zM42.97 31.85l3.82.12c-.32 2.73-1.29 5.32-2.86 7.59l-.63-.49-2.38-1.85a13.05 13.05 0 002.05-5.37m-.9-1.03c-.17 2.48-1.08 4.75-2.53 6.6l3.14 2.44 1.44 1.12c2.17-2.79 3.53-6.23 3.75-9.97l-5.8-.19zM36.52 14.41c2.49 1.04 4.68 2.66 6.42 4.74l-3.12 2.2a13.108 13.108 0 00-4.47-3.3l1.17-3.64m-.63-1.31l-1.78 5.53c2.21.8 4.12 2.23 5.52 4.07l4.75-3.34a17.945 17.945 0 00-8.49-6.26z" _fill="#666"/><g _fill="#666"><path pid="2" d="M19.87 23.39l-4.76-3.35A17.82 17.82 0 0012.08 30v.16l5.81-.19c0-2.43.73-4.68 1.97-6.58zM12.13 31.01a17.74 17.74 0 003.75 9.97l1.44-1.12 3.14-2.44a12.062 12.062 0 01-2.53-6.6l-5.8.19zM24.11 13.1c-3.42 1.2-6.37 3.4-8.49 6.26l4.75 3.34c1.4-1.84 3.31-3.27 5.52-4.07l-1.78-5.53z"/></g><path pid="3" d="M30 12.08c-1.77 0-3.47.27-5.09.75l1.78 5.53c.37-.11.75-.19 1.14-.26l.22-3.15c0-1.06.92-1.99 2.03-1.99.55 0 1.08.24 1.46.64.36.38.56.88.56 1.4l.18 3.12c.34.07.68.14 1.01.24l1.78-5.53c-1.61-.48-3.32-.75-5.09-.75z" _fill="#666"/><path pid="4" d="M31.7 27.02l-.83-11.97c0-.46-.35-.83-.78-.83-.43 0-.79.37-.79.82L28.34 27a3.43 3.43 0 00-1.78 2.98 3.44 3.44 0 106.88.04c0-1.29-.7-2.4-1.74-2.99zM30 31.6c-.88 0-1.6-.73-1.59-1.61s.73-1.6 1.61-1.59c.88 0 1.6.73 1.59 1.61 0 .88-.73 1.6-1.61 1.59z" _fill="#666"/>'
  }
})
