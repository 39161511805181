/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'planned_installation_time__asap': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<g data-name="Gruppe 608"><g data-name="Gruppe 523"><g data-name="Gruppe 396" _fill="#666"><path pid="0" data-name="Pfad 444" d="M45.82 15.35a2.32 2.32 0 00-2.24-1.83H40v3.66A1.79 1.79 0 0138.18 19a1.75 1.75 0 01-1.73-1.78v-3.7H21.18v3.66A1.79 1.79 0 0119.39 19a1.75 1.75 0 01-1.78-1.73v-3.75h-3.56a2.26 2.26 0 00-2.29 2.24v29.58a2.3 2.3 0 001.83 2.24A4.14 4.14 0 0017.34 50h26.79a4.1 4.1 0 004.11-4.08V19.1a4 4 0 00-2.42-3.75zm-33.15 30V23.53H45v21.81a1.35 1.35 0 01-1.32 1.37H14.05a1.39 1.39 0 01-1.38-1.37zm34.7.55a3.21 3.21 0 01-3.2 3.2H17.38a3.14 3.14 0 01-2.69-1.51h28.93a2.27 2.27 0 002.29-2.25V16.4a3.25 3.25 0 011.51 2.7v26.79z"/><path pid="1" data-name="Pfad 445" d="M38.18 10a.87.87 0 00-.87.87v6.31a.87.87 0 00.87.87.84.84 0 00.87-.82v-6.36a.87.87 0 00-.87-.87z"/><path pid="2" data-name="Pfad 446" d="M19.39 10a.87.87 0 00-.86.87v6.31a.86.86 0 00.86.87.84.84 0 00.87-.82v-6.36a.86.86 0 00-.86-.87z"/></g></g><path pid="3" d="M26.49 41.49a1.82 1.82 0 01.12-.65 1.61 1.61 0 01.36-.52 1.66 1.66 0 01.53-.32 1.72 1.72 0 011.31 0 1.77 1.77 0 01.53.36 1.61 1.61 0 01.36.52 1.64 1.64 0 01.13.65 1.59 1.59 0 01-.13.66 1.73 1.73 0 01-.36.52 1.75 1.75 0 01-.53.35 1.85 1.85 0 01-1.31 0 1.63 1.63 0 01-.53-.35 1.73 1.73 0 01-.36-.52 1.76 1.76 0 01-.12-.7zm3-14.4v6.29a18.11 18.11 0 01-.1 2c-.07.64-.15 1.32-.25 2h-1.87c-.1-.72-.19-1.4-.25-2a18.11 18.11 0 01-.1-2v-6.29z" _fill="#666"/></g>'
  }
})
