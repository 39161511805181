import Vue from 'vue';
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        zipcode: '',
        salutation: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
    },
    getters: {
        getField,
        getProgress: (state) => {
            let filled = 0;
            const countedFields = [
                'salutation',
                'first_name',
                'last_name',
                'phone',
                'email',
            ];

            _.forEach(state, (value, key) => {
                if (value !== '' && _.includes(countedFields, key)) {
                    filled += 1;
                }
            });

            return (filled / countedFields.length) * 100;
        },
    },
    mutations: {
        updateField,
        /**
         * Adds customer data that is inputed via a checkbox.
         * @param {string} payload
         * @param {string} payload.name - the name of the input element.
         * @param {string} payload.text - the innerHTML of the label attached to the checkbox input.
         */
        addTerm(state, payload) {
            Vue.set(state, payload.name, true);
            Vue.set(state, `${payload.name}_text`, payload.text);
        },
        /**
         * Removes customer data that was inputed via a checkbox.
         * @param {string} val - the name of the input element.
         */
        removeTerm(state, val) {
            Vue.delete(state, val);
            Vue.delete(state, `${val}_text`);
        },
    },
    actions: {
        addTerm: ({ commit }, payload) => commit('addTerm', payload),
        removeTerm: ({ commit }, val) => commit('removeTerm', val),
    },
};
