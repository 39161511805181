/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pipeline_system_exists__no': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M36.17 27.32a1.28 1.28 0 00-1.28 1.29v20.11A1.28 1.28 0 0036.17 50a1.28 1.28 0 001.28-1.28V28.61a1.28 1.28 0 00-1.28-1.29zM32.61 27.32a1.29 1.29 0 00-1.28 1.29v20.11a1.29 1.29 0 002.57 0V28.61a1.29 1.29 0 00-1.29-1.29zM29.05 27.32a1.29 1.29 0 00-1.28 1.29v20.11a1.29 1.29 0 002.57 0V28.61a1.29 1.29 0 00-1.29-1.29zM25.5 27.32a1.29 1.29 0 00-1.29 1.29v20.11a1.29 1.29 0 002.57 0V28.61a1.29 1.29 0 00-1.28-1.29zM21.94 27.32a1.29 1.29 0 00-1.29 1.29v20.11a1.29 1.29 0 002.57 0V28.61a1.29 1.29 0 00-1.28-1.29zM18.38 27.32a1.28 1.28 0 00-1.28 1.29v20.11a1.28 1.28 0 002.56 0V28.61a1.28 1.28 0 00-1.28-1.29zM14.82 27.32a1.29 1.29 0 00-1.28 1.29V31h-.61v-.54l-2.64.25v1.81l2.64.25v-.53h.61v16.48a1.29 1.29 0 002.57 0V28.61a1.29 1.29 0 00-1.29-1.29zM40 8.5a9 9 0 109 9 9 9 0 00-9-9zm0 16.06a7 7 0 01-6-3.38h3.1V19h-4a7.1 7.1 0 011.54-6l6.1 6.1h-2.63v-.4h-.61v3h.61v-.4h3.56a2 2 0 001-.26l1.87 1.87A7.05 7.05 0 0140 24.56zm5.37-2.48l-1.93-1.93a2.31 2.31 0 00.16-.7V16h.4v-.61h-3.22V16h.4v1.92l-5.76-5.76A7.05 7.05 0 0140 10.44a6.56 6.56 0 011.18.11V15h2.42v-3.57a7.05 7.05 0 011.77 10.65z" _fill="#666"/>'
  }
})
