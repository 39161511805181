<template>
    <div id="app">
        <router-view/>
        <modal-error></modal-error>
        <modal-terms></modal-terms>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import navCommonMixin from '@/mixins/mixin.navCommon';
import ModalError from '@/components/modals/ModalError.vue';
import ModalTerms from '@/components/modals/ModalTerms.vue';
import { sessionStorageEnabled } from './utils/checkSessionStorage';
import { tnsConfig } from './utils/loadTnsConfig';
import { isValidToken } from './utils/validators';
import {
    getUrlQueryString,
    extractQueryStringKnownKeys,
} from './utils/loadUrlParams';
import { eT } from './utils/eventTracking';
import { axiosUseToken } from './axios/config';
import { initAbTests } from './utils/abTests';

export default {
    mixins: [
        navCommonMixin,
    ],
    components: {
        ModalError,
        ModalTerms,
    },
    computed: mapGetters([
        'answers',
    ]),
    mounted() {
        /**
         * Initialise A/B tests.
         */
        initAbTests();

        /**
         * Extract information from the URL query params, if any.
         */
        const [
            preFillValues,
            urlToken,
        ] = extractQueryStringKnownKeys(getUrlQueryString());

        /**
         * Save all pre-answered questions passed via URL, and flag them by adding them to a
         * "preAnswered" object, in order to automatically skip these questions later.
         */
        _.forEach(preFillValues, (obj) => {
            const key = Object.keys(obj)[0];
            const value = obj[key];

            this.$store.commit('collectedData/answers/addAnswerEntry', {
                current: key,
                answer: value,
            });

            this.$store.commit('status/preAnswered/addPreAnsweredEntry', key);
        });

        /**
         * Navigates to starting route.
         * By default, the entry route set in app.json is used, but in some cases this entry
         * route's question will be answered directly via URL, depending on where the visitor is
         * coming from. In that case, the entry route should be the first un-answered
         * question from app.json.
         */
        const startingPath = this.getStartingPath();

        if (this.$route.params.id !== startingPath) {
            this.$router.replace({
                path: startingPath,
            });
        }

        /**
         * Set confirmation page slug from TNS config object.
         */
        this.$store.commit(
            'settings/confirmationPageUrl/setConfirmationPageUrl',
            tnsConfig().confirmationPageDefaultUrl,
        );

        /**
         * Set token from TNS config object if set.
         */
        if (isValidToken(tnsConfig().token)) {
            this.$store.commit('settings/token/setToken', tnsConfig().token);
        }

        /**
         * Set token from URL query params if set.
         */
        if (isValidToken(urlToken)) {
            this.$store.commit('settings/token/setToken', urlToken);
        }

        /**
         * Set referrerId from localStorage if set.
         */
        if (sessionStorageEnabled()) {
            this.$store.commit(
                'settings/referrerId/setReferrerId',
                sessionStorage.getItem('referralId') || '',
            );
        }

        /**
         * Now tell axios to use the token from the current configuration state.
         */
        this.axiosSetToken();

        /**
         * Loads statistics of leads generated today, for marketing use.
         */
        this.loadStatistics();

        /**
         * Analytics events on app initialisation.
         */
        this.$store.dispatch('collectedData/tracking/generateSessionId');
        eT({ event: 'leadform_init' });
    },
    methods: {
        axiosSetToken() {
            axiosUseToken(this.$store.state.settings.token.value);
        },
        async loadStatistics() {
            const statistics = await this.getStatistics();

            if (statistics !== false) {
                this.$store.dispatch(
                    'requests/statistics/setStatistics',
                    statistics,
                );
            }
        },
    },
    watch: {
        $route: {
            handler() {
                eT({
                    event: 'page_loaded',
                    current_virtual_url: this.$route.params.id,
                });
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
    @import 'scss/app.scss';
</style>
