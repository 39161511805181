/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_type__semi_detached_house': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M40.41 25l-7.16 5.44-2.73 2.07-.32.25v14.11h7v-8.44h6.4v8.43h7.2v-14zm-.48 11.66h-2.66V34h2.66zm0-3.49h-2.66v-2.69h2.66zm3.62 3.49h-2.66V34h2.66zm0-3.49h-2.66v-2.69h2.66zM32.51 47.22H6.36V50h46.92v-2.78H32.51zM29.48 32.47l-2.73-2.07-7.16-5.4-10.4 7.85v14h7.21v-8.42h6.38v8.43h7V32.7zm-10.36 4.16h-2.66V34h2.66zm0-3.49h-2.66v-2.66h2.66zm3.61 3.49h-2.66V34h2.66zm0-3.49h-2.66v-2.66h2.66z" _fill="#666"/><path pid="1" _fill="#666" d="M19.59 19.94l-14.5 11 1.99 2.63 12.51-9.49 7.68 5.82 2.52 1.92v-4.15l-10.2-7.73zM40.41 19.94L30.2 27.68v4.15l2.53-1.93 7.68-5.82 12.51 9.49 1.99-2.63-14.5-11z"/>'
  }
})
