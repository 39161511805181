import Vue from 'vue';

export default {
    namespaced: true,
    state: {},
    mutations: {
        addAnswerEntry(state, payload) {
            Vue.set(state, payload.current, payload.answer);
        },
        removeAnswerEntry(state, key) {
            Vue.delete(state, key);
        },
    },
};
