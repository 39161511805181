/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'energy_source_old__natural_gas': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" _fill="#666" d="M17.72 47.96h26.56V50H17.72zM29.41 46.48c-1.73-.71-2.85-2.62-3.19-5.24-.76-6 2.82-10.86 4.78-14.72 2 3.86 5.54 8.75 4.78 14.72-.34 2.62-1.46 4.53-3.19 5.24 4.72-.69 7.78-4.43 8.6-9.89C42.83 25.8 35.17 17 31 10c-4.17 7-11.83 15.8-10.19 26.59.82 5.46 3.88 9.2 8.6 9.89z"/><path pid="1" d="M31 31.36c-1.22 2.8-3.46 6.35-3 10.69.26 2.44 1.33 4 3 4 1.65 0 2.72-1.59 3-4 .46-4.34-1.78-7.89-3-10.69z" _fill="#666"/>'
  }
})
