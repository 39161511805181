/**
 * Return an object where all required key value pairs for common error handling are defined.
 * @param  {Object} [errorData] - Key value that override the default values.
 * @returns  {Object} Object contain the default key value pairs:
 * - `showModal=true`
 * or values overridden by the argument `errorData`
 */
const getFullErrorData = (errorData) => {
    const defaultErrorData = {
        showModal: true,
        track: true,
    };

    if (errorData === undefined) {
        return defaultErrorData;
    }

    return { ...defaultErrorData, ...errorData };
};

/**
 * Check if the request was expected to raise this type of error based on the error's response.
 * @returns {boolean} True if the response was expected, otherwise false.
 */
const isExpectedResponse = error => (
    /**
     * check if the error has a response. It will not if the domain is blocked.
     */
    error.response !== undefined && error.response.status <= 399
);

export default {
    getFullErrorData,
    isExpectedResponse,
};
