<template>
    <div>
        <div class="controls"
             v-show="canNavigateNextStep || canNavigatePrevStep">
            <offer-progress-mobile-linear></offer-progress-mobile-linear>
            <div class="controls__container--left">
                <div class="controls__button controls__button--prev"
                        v-if="canNavigatePrevStep"
                        @click="goBack"
                        tabindex="98">
                    <BackSvg />
                </div>
            </div>
            <div class="controls__container--center">
                <controls-button
                    :class="{'controls__button--sm-block': showControlButton}"
                >
                </controls-button>
            </div>
            <div class="controls__container--right"></div>
        </div>
    </div>
</template>

<script>
import controlsMixin from '@/mixins/mixin.controls';
import OfferProgressMobileLinear from '@/components/OfferProgressMobileLinear.vue';
import ControlsButton from '@/components/ControlsButton.vue';
import BackSvg from '../../public/svg/back.svg';
import appConfig from '@/config/app.json';

export default {
    inject: ['$validator'],
    mixins: [controlsMixin],
    components: {
        ControlsButton,
        OfferProgressMobileLinear,
        BackSvg,
    },
    computed: {
        showControlButton() {
            return appConfig.steps[this.$route.params.id].step_key === 'inquire';
        },
    },
};
</script>
