import _ from 'lodash';
import Cookies from 'js-cookie';

/**
 * List of tracking-related cookies keys to collect.
 */
const COOKIES_MAP = [
    'msclkid',
    'thermondo__page_landing__last_visit',
    'thermondo__page_referrer__last_visit',
    'thermondo__medium__last_visit',
    'thermondo__source__last_visit',
    'thermondo__campaign__last_visit',
    'google_analytics__gclid',
    '_ga',
];

/**
 * Returns cookie value for given cookie key.
 * @param key
 * @returns {string}
 */
const getCookie = key => Cookies.get(key) || 'none';

/**
 * Returns all cookies listed in COOKIES_MAP.
 * @returns {object}
 */
const getTrackingCookies = () => {
    const cookies = {};
    _.forEach(COOKIES_MAP, (value) => {
        cookies[value] = getCookie(value);
    });
    return cookies;
};

// eslint-disable-next-line
export { getTrackingCookies };
