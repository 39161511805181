/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pv_system_existing_or_planned__not_interested': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M29.79 14.88c-9.77 0-17.71 7.94-17.71 17.71S20.02 50.3 29.79 50.3 47.5 42.36 47.5 32.59s-7.94-17.71-17.71-17.71zM44.5 32.59c0 3.32-1.12 6.37-2.97 8.83L20.96 20.85c2.46-1.86 5.52-2.97 8.83-2.97 8.11 0 14.71 6.6 14.71 14.71zm-29.42 0c0-3.72 1.4-7.1 3.68-9.7l20.73 20.73c-2.59 2.28-5.98 3.68-9.7 3.68-8.11 0-14.71-6.6-14.71-14.71z" _fill="#666"/>'
  }
})
