/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pipeline_system_exists__yes': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M43.79 13.5v32.29H36.2V32.21h4.3a.71.71 0 00.71-.71v-18h-1.42v17.29H36.2v-2.18a1.29 1.29 0 10-2.57 0v2.18h-1v-2.18a1.28 1.28 0 10-2.56 0v2.18h-1v-2.18a1.29 1.29 0 10-2.57 0v2.18h-1v-2.18a1.29 1.29 0 10-2.57 0v2.18H22v-2.18a1.29 1.29 0 10-2.57 0v2.18h-1v-2.18a1.29 1.29 0 10-2.57 0v2.18h-1v-2.18a1.28 1.28 0 00-1.28-1.29 1.28 1.28 0 00-1.28 1.29v2.18h-.8v1.42h.79v13.58h-.79v1.42h.79v1.51A1.28 1.28 0 0013.57 50a1.28 1.28 0 001.28-1.28v-1.51h1v1.51a1.29 1.29 0 002.57 0v-1.51h1v1.51a1.29 1.29 0 002.57 0v-1.51h1v1.51a1.29 1.29 0 002.57 0v-1.51h1v1.51a1.29 1.29 0 002.57 0v-1.51h1v1.51a1.28 1.28 0 002.56 0v-1.51h1v1.51a1.29 1.29 0 002.57 0v-1.51h8.3a.71.71 0 00.71-.71v-33zM14.85 45.79V32.21h1v13.58zm3.56 0V32.21h1v13.58zm3.56 0V32.21h1v13.58zm3.56 0V32.21h1v13.58zm3.56 0V32.21h1v13.58zm3.55 0V32.21h1v13.58z" _fill="#666"/>'
  }
})
