/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_type__middle_terraced_house': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" _fill="#666" d="M33.32 47.22H.89V50h58.2v-2.78H33.32z"/><g _fill="#666"><path pid="1" d="M47.39 27.49l7.94 6.77v12.05h-4.85v-7.69H44.3v7.69h-4.69V34.13l.07-.06 2.22-1.89 5.49-4.68m0-.66l-5.82 4.96-2.22 1.89-.25.21v12.92h5.69v-7.69h5.18v7.69h5.85V34.04l-8.44-7.19z"/><path pid="2" d="M47.35 23l11.08 9.52-1.05 1.58-9.7-8.34-.33-.28-.33.28-6.21 5.34-1.21 1.04v-2.48L47.35 23m0-.66l-8.25 7.09v3.8l2.04-1.75 6.21-5.34 10.12 8.71 1.61-2.41-11.74-10.09z"/></g><g _fill="#666"><path pid="3" d="M12.59 27.49l5.49 4.68 2.22 1.9.07.06v12.18h-4.69v-7.69H9.5v7.69H4.65V34.26l7.94-6.77m0-.66l-8.44 7.19V46.8H10v-7.69h5.18v7.69h5.69V33.88l-.25-.21-2.22-1.89-5.82-4.96z"/><path pid="4" d="M12.63 22.93l7.75 6.66v2.48l-1.21-1.04-6.21-5.34-.33-.28-.33.28-9.7 8.34-1.05-1.58 11.08-9.52m0-.66L.89 32.36l1.61 2.41 10.12-8.71 6.21 5.34 2.04 1.75v-3.8l-8.25-7.09z"/></g><g _fill="#666"><path pid="5" d="M29.93 26.84l-5.96 4.96-2.28 1.89-.27.22v12.9h5.85v-7.7h5.32v7.69h5.99V34.02l-8.65-7.19zm-.4 10.15h-2.29V34.7h2.29v2.29zm0-3.02h-2.29v-2.29h2.29v2.29zm3.12 3.02h-2.29V34.7h2.29v2.29zm0-3.02h-2.29v-2.29h2.29v2.29z"/><path pid="6" d="M38.58 29.48l-8.66-7.35-8.5 7.21V33.2l2.11-1.8 6.39-5.41 8.66 7.34v-3.85z"/></g>'
  }
})
