<template>
    <div class="app-info" v-if="testVersion">
        <div class="app-info__intro">
            App info
        </div>
        <button class="app-info__toggle" @click="showItems = !showItems">
            <span v-if="showItems">-</span>
            <span v-else>+</span>
        </button>
        <div v-show="showItems">
            <div class="app-info__item">
                This version is using the following endpoint URL:
                <pre>{{ testVersionEndpointUrl }}</pre>
                <strong>It is intended for test only!</strong>
            </div>
        </div>
    </div>
</template>

<script>
import urljoin from 'url-join';

export default {
    data() {
        return {
            showItems: true,
            testVersion: process.env.VUE_APP_BACKEND_API_SANDBOX_MODE === 'true',
            testVersionEndpointUrl: urljoin(
                process.env.VUE_APP_BACKEND_API_URL_ROOT,
                process.env.VUE_APP_BACKEND_API_URL_OPPORTUNITY_ENDPOINT,
            ),
        };
    },
};
</script>
