/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heat_distribution__miscellaneous': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M17.5 47.56a2.38 2.38 0 01.19-1 2.43 2.43 0 01.5-.78 2.5 2.5 0 01.78-.53 2.34 2.34 0 011-.2 2.39 2.39 0 011 .2 2.52 2.52 0 011.31 1.31 2.39 2.39 0 01.2 1 2.32 2.32 0 01-.2 1 2.63 2.63 0 01-.53.78 2.29 2.29 0 01-.78.51 2.38 2.38 0 01-1 .19 2.34 2.34 0 01-1-.19 2.29 2.29 0 01-.78-.51 2.54 2.54 0 01-.5-.78 2.32 2.32 0 01-.19-1zm10.05 0a2.56 2.56 0 01.18-1 2.63 2.63 0 01.51-.78 2.46 2.46 0 01.77-.53 2.4 2.4 0 011.92 0 2.52 2.52 0 011.31 1.31 2.38 2.38 0 01.19 1 2.32 2.32 0 01-.19 1 2.63 2.63 0 01-.53.78 2.29 2.29 0 01-.78.51 2.52 2.52 0 01-1.92 0 2.25 2.25 0 01-.77-.51 2.77 2.77 0 01-.51-.78 2.49 2.49 0 01-.18-1zm10.06 0a2.57 2.57 0 01.19-1 2.29 2.29 0 01.51-.78 2.35 2.35 0 01.77-.53 2.39 2.39 0 011-.2 2.34 2.34 0 011 .2 2.55 2.55 0 01.79.53 2.48 2.48 0 01.52.78 2.39 2.39 0 01.2 1 2.32 2.32 0 01-.2 1 2.6 2.6 0 01-.52.78A2.55 2.55 0 0140 50a2.38 2.38 0 01-1-.19 2.16 2.16 0 01-.77-.51 2.4 2.4 0 01-.51-.78 2.49 2.49 0 01-.11-.96z" _fill="#666"/>'
  }
})
