/**
 * Returns true if the token is valid and false if invalid.
 * Valid tokens are hexadecimal strings with lowercase letters (digits 0-9 and/or letters a-f)
 * of length 40.
 * Raises sentry warning when the token is invalid.
 * @param {string} token
 * @returns {boolean}
 */
const isValidToken = token => /^[0-9a-f]{40}$/.test(token);

// eslint-disable-next-line
export { isValidToken };
