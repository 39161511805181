/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pv_system_existing_or_planned__unsure': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M19.15 22.06c.53-1.38 1.32-2.65 2.34-3.72 1.02-1.06 2.25-1.9 3.62-2.46 1.51-.61 3.12-.9 4.75-.88 1.93-.06 3.84.26 5.65.93 1.36.52 2.6 1.31 3.65 2.31.88.84 1.56 1.87 2 3 .38.96.58 1.97.6 3 .04 1.28-.16 2.56-.6 3.77a9.185 9.185 0 01-3.42 4.31c-.72.5-1.4 1-2 1.48-.64.49-1.21 1.06-1.7 1.7-.52.7-.84 1.52-.93 2.38v1.81h-6.49v-2.18c.05-1.18.32-2.34.78-3.43.39-.86.89-1.65 1.5-2.37.55-.63 1.15-1.2 1.81-1.71.64-.48 1.23-.95 1.77-1.43.52-.45.96-.98 1.31-1.57.36-.66.52-1.4.46-2.15a4.33 4.33 0 00-1.08-3.24 4.09 4.09 0 00-3-1.05c-.77-.02-1.53.15-2.22.5-.6.32-1.13.78-1.52 1.34a5.66 5.66 0 00-.89 1.95c-.19.79-.28 1.6-.28 2.41h-7c.01-1.61.31-3.21.88-4.72M33.43 46.26c0 2.07-1.68 3.74-3.75 3.73-2.07 0-3.74-1.68-3.73-3.75 0-2.06 1.68-3.73 3.74-3.73 2.07 0 3.74 1.67 3.74 3.74v.01" _fill="#666"/>'
  }
})
