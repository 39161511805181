import answers from './answers';
import customer from './customer';
import tracking from './tracking';

export default {
    namespaced: true,
    modules: {
        answers,
        customer,
        tracking,
    },
};
