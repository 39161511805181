import axios from 'axios';
import {
    onSuccess,
    onError,
    beforeRequestSuccess,
    beforeRequestError,
} from '../interceptors/loader';

const axiosUseToken = (token) => {
    axios.defaults.headers.common.Authorization = `Token ${token}`;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.interceptors.request.use(beforeRequestSuccess, beforeRequestError);
    axios.interceptors.response.use(onSuccess, onError);
};

// eslint-disable-next-line
export { axiosUseToken };
