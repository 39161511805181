<template>
    <div v-if="show"
         class="offer-progress-mobile-linear">
        <div class="offer-progress-mobile-linear__box">
            <div class="offer-progress-mobile-linear__fill"
                 :style="{ width: percentage + '%' }">
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStepKeyFromRoute } from '../utils/appConfigGetters';

export default {
    computed: {
        ...mapGetters({
            percentage: 'collectedData/customer/getProgress',
        }),

        /**
         * This component should only be shown on the inquire page. This is needed since it live in
         * a common component (Controls.vue).
         */
        show() {
            return getStepKeyFromRoute(this.$route.params.id) === 'inquire';
        },
    },
};
</script>
