import 'vue-svgicon/dist/polyfill';
import Vue from 'vue';
import Vuex from 'vuex';
import VueScrollTo from 'vue-scrollto';
import VeeValidate, { Validator } from 'vee-validate';
import SvgIcon from 'vue-svgicon';
import appConfig from './config/app.json';
import validationConfig from './config/validation.json';
import router from './router';
import store from './store';
import App from './App.vue';

// eslint-disable-next-line
import { sentryClient } from '@/utils/sentryClient';

Vue.use(Vuex);
Vue.use(VeeValidate, {
    events: 'blur',
    aria: true,
});
Validator.localize('de', validationConfig);
Vue.use(SvgIcon);

Vue.use(VueScrollTo, {
    container: 'body',
    duration: 500,
    easing: 'ease',
    offset: 0,
    cancelable: true,
    onDone: false,
    onCancel: false,
    x: false,
    y: true,
});

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
    data: {
        appConfig,
    },
    el: '#app',
    router,
    store,
    render: h => h(App),
});
