import httpErrors from './httpErrors';
import progress from './progress';
import preAnswered from './preAnswered';

export default {
    namespaced: true,
    modules: {
        httpErrors,
        progress,
        preAnswered,
    },
};
