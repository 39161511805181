import { mapState } from 'vuex';
import { eT } from '@/utils/eventTracking';
import navCommonMixin from '@/mixins/mixin.navCommon';

export default {
    mixins: [navCommonMixin],
    computed: {
        ...mapState('ui', [
            'loading',
            'redirecting',
            'ctaLabel',
            'canNavigateNextStep',
        ]),
    },
    methods: {
        goBack() {
            eT({ event: 'click_back' });
            this.navigatePrevStep();
            this.$store.commit('ui/setShowErrorInfoBox', false);
        },
        goNext() {
            eT({ event: 'click_forward' });
            this.navigateNextStep();
        },
    },
};
