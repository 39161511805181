import _ from 'lodash';
import Vue from 'vue';
import { sentryClient } from '@/utils/sentryClient';

export default {
    namespaced: true,
    modules: {
        statistics: {
            namespaced: true,
            state: {
                amountOfLeadsToday: false,
            },
            mutations: {
                setAmountOfLeadsToday: (state, amountOfLeadsToday) => Vue.set(
                    state, 'amountOfLeadsToday', amountOfLeadsToday,
                ),
            },
            actions: {
                setAmountOfLeadsToday: ({ commit }, amountOfLeadsToday) => commit(
                    'setAmountOfLeadsToday', amountOfLeadsToday,
                ),
                setStatistics: ({ dispatch }, statistics) => {
                    if (_.has(statistics, 'created_today')) {
                        dispatch('setAmountOfLeadsToday', statistics.created_today);
                    } else {
                        sentryClient.captureMessage(
                            '(setStatistics) The payload must have the attribute "created_today"',
                            {
                                level: 'error',
                                extra: {
                                    payload: statistics,
                                },
                            },
                        );
                    }
                },
            },
        },
    },
};
