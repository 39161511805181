<template>
    <div v-if="referrerId.value" class="customer-referral-badge">
        <div class="customer-referral-badge__icon-wrapper">
            <CustomerReferralBadgeSvg />
        </div>
        <div>
            <div class="customer-referral-badge__title">
                Wir wurden Ihnen empfohlen
            </div>
            <div class="customer-referral-badge__content">
                Belohnen Sie Ihren Heizungswechsel mit einer Prämie von 300 €
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import CustomerReferralBadgeSvg from '../../public/svg/customer-referral-badge.svg';

export default {
    components: {
        CustomerReferralBadgeSvg,
    },
    computed: {
        ...mapState('settings', [
            'referrerId',
        ]),
    },
};
</script>
