/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_heated_area__401_or_more': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" data-name="Pfad 488" d="M46.59 15H12.5v35h35V15zm0 34.09H13.41V15.91v33.18h33.18V15.91z" _fill="#666"/>'
  }
})
