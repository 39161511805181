import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        event_order: 1,
        session_id: undefined,
    },
    mutations: {
        incrementEventOrder(state) {
            let eventOrderClone = state.event_order;
            Vue.set(state, 'event_order', eventOrderClone += 1);
        },
        generateSessionId(state) {
            const timestamp = Date.now();
            const random = Math.floor(Math.random() * 100000);
            Vue.set(state, 'session_id', parseInt(timestamp + random, 10));
        },
    },
    actions: {
        incrementEventOrder({ commit }) {
            commit('incrementEventOrder');
        },
        generateSessionId({ commit }) {
            commit('generateSessionId');
        },
    },
};
