/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'floor_new_heating__1': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<path pid="0" d="M83 68.683H18v4h65v-4zM51 26.367l22.425 17.45L77 38.983 51 18.75 25 38.983l3.575 4.834L51 26.367zM32.433 52.067v.766h37.5V52h-37.5v.067z" _fill="#000"/><path pid="1" d="M32.76 37.283H32v32.45h.827v-32.45h-.066zM69.934 37.283h-.761v32.45H70v-32.45h-.066z" _fill="#000"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M44.567 39.667V35h4.666v1.5h-3.166v3.167h-1.5zm14 0V35H53.9v1.5h3.167v3.167h1.5zm0 4.666h-1.5V47.5H53.9V49h4.667v-4.667zM49.233 49v-1.5h-3.166v-3.167h-1.5V49h4.666z" _fill="#000"/>'
  }
})
