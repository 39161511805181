import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import store from '@/store';

Vue.use(VueScrollTo);

const onSuccess = (response) => {
    store.dispatch('ui/setLoading', false);
    return response;
};

const onError = async (error) => {
    store.dispatch('ui/setLoading', false);
    if (error.response && error.response.status >= 500 && error.response.status < 600) {
        await store.dispatch('ui/setShowErrorInfoBox', true);
        const element = document.querySelector('[data-error-info-box]');
        VueScrollTo.scrollTo(element);
    }
    return Promise.reject(error);
};

const beforeRequestSuccess = async (config) => {
    store.dispatch('ui/setLoading', true);
    return config;
};

const beforeRequestError = (error) => {
    store.dispatch('ui/setLoading', false);
    return Promise.reject(error);
};

export {
    onSuccess,
    onError,
    beforeRequestSuccess,
    beforeRequestError,
};
