/**
 * This library of small helpers is made to facilitate tricky or repetitive config data extraction.
 */
import _ from 'lodash';
import appConfig from '@/config/app.json';

/**
 * Returns an array of backend keys from all steps.
 * @returns {Array}
 */
const getStepsStepKeys = () => {
    const stepsKeys = [];

    _.forEach(appConfig.steps, (stepObj) => {
        stepsKeys.push(stepObj.step_key);
    });

    return stepsKeys;
};

/**
 * Returns route from a certain key.
 * @param key: {String}
 * @returns {String}
 */
const getRouteFromStepKey = key => _.findKey(appConfig.steps, { step_key: key });

/**
 * Returns key from a certain route.
 * @param route: {String}
 * @returns {String}
 */
const getStepKeyFromRoute = (route) => {
    let stepKey;

    try {
        stepKey = appConfig.steps[route].step_key;
    } catch (e) {
        stepKey = undefined;
    }

    return stepKey;
};

/**
 * Returns next step's path from a question's "next_step" or choice's "next_step" object based on
 * context.
 * @param nextStepObj: {Object}
 * @param collectedAnswersObj: {Object}
 * @returns nextStepPath: {String}
 */
const getNextStepPathFromNextStepType = (nextStepObj, collectedAnswersObj = {}) => {
    let nextStepPath;

    /**
     * Conditions.
     *
     * This is useful when the next step's path has to change based on a previous answer or a set
     * of multiple previous answers, allowing to build complex scenarios.
     *
     * Note that "conditions" can contain multiple conditions in the same array, and all of them
     * have to be met in order to be valid (AND).
     *
     * Here is a full example of a "next_step" of type "condition":

    "next_step": {
        "type": "condition",
        "default_path": "my_default_path",
        "condition_paths": [
            {
                "conditions": [
                    {
                        "step": "foo_1",
                        "answer_value": "bar_1"
                    },
                    {
                        "step": "foo_2",
                        "answer_value": "bar_2"
                    }
                ],
                "path": "my_other_path_1"
            },
            {
                "conditions": [
                    {
                        "step": "foo_3",
                        "answer_value": "bar_3"
                    }
                ],
                "path": "my_other_path_2"
            },
        ]
    }

     * All conditions have to be met in order to be valid.
     */
    if (nextStepObj.type === 'condition') {
        nextStepPath = nextStepObj.default_path;

        // eslint-disable-next-line no-restricted-syntax
        for (const conditionSet of nextStepObj.condition_paths) {
            let didMatch = true;

            conditionSet.conditions.forEach((condition) => {
                didMatch = didMatch
                    && Object.prototype.hasOwnProperty.call(
                        collectedAnswersObj,
                        condition.step,
                    )
                    && collectedAnswersObj[condition.step] === condition.answer_value;
            });

            if (didMatch) {
                nextStepPath = conditionSet.path;
                break;
            }
        }
    }

    /**
     * No particular logic, simply returns the path value.
     */
    if (nextStepObj.type === 'simple') {
        nextStepPath = nextStepObj.path;
    }

    return nextStepPath;
};

const getRoutesWithBackendKeys = () => {
    const routesWithBackendKeys = [];

    _.forEach(appConfig.steps, (stepObj) => {
        if (
            Object.prototype.hasOwnProperty.call(
                stepObj,
                'backend_key',
            )
        ) {
            routesWithBackendKeys.push(getRouteFromStepKey(stepObj.step_key));
        }
    });

    return routesWithBackendKeys;
};

/**
 * Get the entry route.
 * @returns {String}
 */
const getEntryRoute = () => appConfig.entry;

/**
 * Get all steps.
 * @returns {Object}
 */
const getSteps = () => appConfig.steps;

/**
 * Get step object from route.
 * @returns {Object}
 */
const getStep = route => appConfig.steps[route];

export {
    getStepsStepKeys,
    getRouteFromStepKey,
    getStepKeyFromRoute,
    getRoutesWithBackendKeys,
    getNextStepPathFromNextStepType,
    getEntryRoute,
    getSteps,
    getStep,
};
