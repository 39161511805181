/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'years_since_old_heating_was_mounted__no_heating_installed': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<g data-name="Gruppe 617"><g data-name="Gruppe 554" _fill="#666"><path pid="0" data-name="Pfad 670" d="M30 14.9c8.34 0 15.1 6.76 15.1 15.1S38.34 45.1 30 45.1 14.9 38.34 14.9 30c.01-8.33 6.76-15.08 15.1-15.1m0-2.4c-9.66 0-17.5 7.84-17.5 17.5S20.34 47.5 30 47.5 47.5 39.66 47.5 30 39.66 12.5 30 12.5z"/><path pid="1" data-name="Rechteck 591" d="M17.486 18.403l1.697-1.697L43.12 40.64l-1.697 1.697z"/></g></g><g data-name="Gruppe 604"><g data-name="Gruppe 521" _fill="#676767"><path pid="2" data-name="Pfad 650" d="M20.97 21.39c-.56 0-1.02.46-1.02 1.02v13.64h-.52v1.28h.5v1.12c0 .56.46 1.02 1.02 1.02s1.02-.46 1.02-1.02V22.42c0-.56-.44-1.02-1.01-1.03z"/><path pid="3" data-name="Pfad 651" d="M23.8 21.39c-.56 0-1.02.46-1.02 1.02v16.04c.01.56.48 1.01 1.04 1 .55-.01.99-.45 1-1V22.42c0-.56-.45-1.02-1.01-1.03h-.01z"/><path pid="4" data-name="Pfad 652" d="M26.64 21.39c-.56 0-1.02.46-1.02 1.02v16.04c-.01.56.43 1.03 1 1.04.56.01 1.03-.43 1.04-1V22.42c0-.56-.45-1.02-1.01-1.03h-.01z"/><path pid="5" data-name="Pfad 653" d="M29.48 21.39c-.56 0-1.02.46-1.02 1.02v16.04c-.01.56.43 1.03 1 1.04.56.01 1.03-.43 1.04-1V22.42c0-.57-.45-1.03-1.02-1.03z"/><path pid="6" data-name="Pfad 654" d="M32.32 21.39c-.56 0-1.02.46-1.02 1.02v16.04c-.01.56.43 1.03 1 1.04.56.01 1.03-.43 1.04-1V22.42c0-.56-.46-1.02-1.02-1.03z"/><path pid="7" data-name="Pfad 655" d="M35.16 21.39c-.56 0-1.02.46-1.02 1.02v16.04c.01.56.48 1.01 1.04 1 .55-.01.99-.45 1-1V22.42c0-.56-.46-1.02-1.02-1.03z"/><path pid="8" data-name="Pfad 656" d="M39.5 23.92v.43h-.48v-1.93c.01-.56-.43-1.03-1-1.04-.56-.01-1.03.43-1.04 1v16.08c-.01.56.43 1.03 1 1.04.56.01 1.03-.43 1.04-1V37.34h.48v-1.28h-.48V25.34h.48v.43l2.11-.2v-1.45l-2.11-.2z"/></g></g>'
  }
})
