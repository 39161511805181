/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'people_count__3_4': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<g data-name="Gruppe 488" _fill="#666"><path pid="0" data-name="Pfad 566" d="M42.71 30.81a3.15 3.15 0 00-.92-2.23 3.21 3.21 0 00-.73-.54 3 3 0 00-.86-.32 3.47 3.47 0 00-.64-.06 3.22 3.22 0 00-1.65.47 4.12 4.12 0 00-.77.67 2.48 2.48 0 00-.19.26 3.09 3.09 0 00-.54 1.75 3.13 3.13 0 001.15 2.42 3.14 3.14 0 005.15-2.42z"/><path pid="1" data-name="Pfad 567" d="M44.17 39.24A4.43 4.43 0 0042.81 36a2.79 2.79 0 00-.42-.35 4.53 4.53 0 00-.63-.45 4.71 4.71 0 00-2.2-.57 4.43 4.43 0 00-2.57.79c-.14.1-.23.16-.35.26a7.11 7.11 0 01.54 2.7v9.07h7z"/><path pid="2" data-name="Pfad 568" d="M23.62 30.81a3.15 3.15 0 00-.92-2.23A3.21 3.21 0 0022 28a3 3 0 00-.86-.32 3.47 3.47 0 00-.64-.06 3.19 3.19 0 00-1.65.47 4.12 4.12 0 00-.77.67 2.48 2.48 0 00-.19.26 3.09 3.09 0 00-.54 1.75 3.13 3.13 0 001.15 2.42 3.14 3.14 0 005.15-2.42z"/><path pid="3" data-name="Pfad 569" d="M23.37 35.68s-.07 0-.1-.07a4.83 4.83 0 00-.64-.44 4.65 4.65 0 00-2.19-.58 4.63 4.63 0 00-4.61 4.62v8.24h7v-9.07a8.06 8.06 0 01.54-2.7z"/><circle pid="4" data-name="Ellipse 3" cx="30.02" cy="26.45" r="4.45"/><path pid="5" data-name="Pfad 570" d="M24.16 35.52c-.09.22-.19.41-.28.63a6.58 6.58 0 00-.42 2.23V50h13V38.38a6.5 6.5 0 00-12.35-2.86z"/></g>'
  }
})
