/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'water_heating__no': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M45.42 42.8a1.56 1.56 0 01-1.38.83H40a1.53 1.53 0 01-1.37-.83L38 41.62c-2.16-.16-4.25-.35-4.84-.39v7.59a1 1 0 001 1H50a1 1 0 001-1v-7.59c-.47 0-2.63.23-5 .39zM34.17 20a1 1 0 00-1 1v19.21H50.98V21a1 1 0 00-1-1zM23.06 35.17a9.19 9.19 0 01-.6-.93 2 2 0 01-.27-.6.7.7 0 01-.05-.32.7.7 0 01-.06.32 3.24 3.24 0 01-.27.6c-.22.33-.38.66-.6.93a3 3 0 00-.38.76 1.26 1.26 0 00.71 1.52 2 2 0 00.54.11 1.92 1.92 0 00.54-.11 1.24 1.24 0 00.82-1.52 4.49 4.49 0 00-.38-.76z" _fill="#666"/><path pid="1" d="M19.51 19.73A10.77 10.77 0 1030.28 30.5a10.78 10.78 0 00-10.77-10.77zM10.28 30.5a9.22 9.22 0 0114.92-7.26l-4.49 4.5h-1.16V26a.49.49 0 00-.49-.49h-.22v-.71H21a.6.6 0 00.6-.6V24a.6.6 0 00-.6-.6h-5a.61.61 0 00-.6.6v.11a.6.6 0 00.6.59h2.12v.71h-.21a.49.49 0 00-.49.49v1.74h-2.5v-.81a.73.73 0 00-.71-.71h-.69v5.49h.69a.72.72 0 00.71-.7v-.87h3.44l-6.12 6.11a9.19 9.19 0 01-1.96-5.65zm9.23 9.23a9.18 9.18 0 01-6.21-2.42l7.23-7.23h.41v3h2.39v-4.15a1.2 1.2 0 00-.62-1l3.61-3.61a9.21 9.21 0 01-6.81 15.44z" _fill="#666"/>'
  }
})
