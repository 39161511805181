/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'energy_source_new__air_to_water_heat_pump': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<g _fill="#666"><path pid="0" d="M48.4 12.4H11.5c-2.8 0-5.2 2.3-5.2 5.2v24.9c0 2.8 2.3 5.2 5.2 5.2h36.9c2.8 0 5.2-2.3 5.2-5.2v-25c0-2.8-2.3-5.1-5.2-5.1zm2.8 30c0 1.5-1.2 2.7-2.7 2.7h-37c-1.5 0-2.7-1.2-2.7-2.7V17.5c0-1.5 1.2-2.7 2.7-2.7h36.9c1.5 0 2.7 1.2 2.7 2.7.1 0 .1 24.9.1 24.9z"/><path pid="1" d="M21.8 20.2c-5.4 0-9.8 4.4-9.8 9.8s4.4 9.8 9.8 9.8 9.8-4.4 9.8-9.8-4.4-9.8-9.8-9.8zm0 17.7c-4.4 0-7.9-3.5-7.9-7.9s3.5-7.9 7.9-7.9 7.9 3.5 7.9 7.9-3.6 7.9-7.9 7.9z"/><path pid="2" d="M26.9 30.5c-1.3-.8-2.9-.5-4.1-.3-.1-.1-.1-.2-.2-.3 0-.1-.1-.2-.2-.3.7-.9 1.6-2.2 1.6-3.7 0-1.3-1.1-2.3-2.4-2.3-1.4 0-2.4 1.1-2.4 2.3 0 1.5 1 2.8 1.7 3.7-.1.1-.1.2-.1.3-.1.1-.1.2-.2.4-1.1-.1-2.7-.3-4 .5-1.1.6-1.5 2.1-.8 3.3.3.6.8 1 1.4 1.2.2.1.4.1.7.1.4 0 .8-.1 1.1-.3 1.3-.8 1.9-2.3 2.3-3.3h.7c.4 1 1.1 2.4 2.4 3.2.3.2.7.3 1.1.3.2 0 .4 0 .7-.1.6-.2 1.1-.6 1.4-1.2.3-.6.4-1.2.3-1.8 0-.8-.4-1.4-1-1.7zm-8.5 3.3c-.2.1-.5.2-.8.1-.3-.1-.5-.3-.7-.6-.3-.6-.2-1.3.3-1.6.8-.5 1.9-.4 2.9-.3-.4.9-.9 1.9-1.7 2.4zm2-8c0-.6.5-1.1 1.2-1.1s1.2.5 1.2 1.1c0 1-.6 1.9-1.1 2.6-.6-.7-1.3-1.6-1.3-2.6zm6.3 7.4c-.2.3-.4.5-.7.6-.3.1-.6 0-.8-.1-.8-.5-1.4-1.5-1.7-2.3.9-.1 2.1-.2 2.9.2.2.1.4.4.5.7 0 .3-.1.6-.2.9zM45.8 20.8h-9.9c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2h9.9c.7 0 1.2-.5 1.2-1.2s-.5-1.2-1.2-1.2zM45.8 26.1h-9.9c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2h9.9c.7 0 1.2-.5 1.2-1.2s-.5-1.2-1.2-1.2zM45.8 31.4h-9.9c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2h9.9c.7 0 1.2-.5 1.2-1.2 0-.6-.5-1.2-1.2-1.2zM45.8 36.7h-9.9c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2h9.9c.7 0 1.2-.5 1.2-1.2 0-.6-.5-1.2-1.2-1.2z"/></g>'
  }
})
