/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'floor_new_heating__0': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<path pid="0" d="M83 60H18v4h65v-4zM46 22.596L68.425 40 72 35.18 46 15 20 35.18 23.575 40 46 22.596z" _fill="#000"/><path pid="1" d="M27 39.233V40h38v-.833H27v.066z" _fill="#000"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M27.76 34H27v50h38V34h-.827v49.085H27.827V34h-.066z" _fill="#000"/><path pid="3" fill-rule="evenodd" clip-rule="evenodd" d="M39 47.667V43h4.667v1.5H40.5v3.167H39zm14 0V43h-4.667v1.5H51.5v3.167H53zm0 4.666h-1.5V55.5h-3.167V57H53v-4.667zM43.667 57v-1.5H40.5v-3.167H39V57h4.667zM68 47.667V43h4.667v1.5H69.5v3.167H68zm14 0V43h-4.667v1.5H80.5v3.167H82zm0 4.666h-1.5V55.5h-3.167V57H82v-4.667zM72.667 57v-1.5H69.5v-3.167H68V57h4.667zM39 71.667V67h4.667v1.5H40.5v3.167H39zm14 0V67h-4.667v1.5H51.5v3.167H53zm0 4.666h-1.5V79.5h-3.167V81H53v-4.667zM43.667 81v-1.5H40.5v-3.167H39V81h4.667z" _fill="#000"/>'
  }
})
