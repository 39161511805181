import { captureException } from '@sentry/browser';

/**
 * Capture the request error in Sentry
 * @param {Error} error - The error object
 * @param {Response} response - The response object
 */
const sentryCaptureRequestError = async (error, response) => {
    // Axios response object has a different structure
    let body = response?.text ? await response.text() : null;
    if (response?.data) {
        body = JSON.stringify(response.data);
    }
    const url = response?.url ?? response?.request?.responseURL;

    captureException(error, {
        extra: {
            request: { url },
            response: {
                status: response?.status,
                body,
            },
        },
        tags: { url },
    });
};

export default sentryCaptureRequestError;
