export default {
    methods: {
        scrollToElement(el) {
            const scrollIntoViewIsSupported = el.scrollIntoView !== undefined;
            const scrollOptions = {
                behavior: 'smooth',
                block: 'center',
            };
            if (scrollIntoViewIsSupported) {
                el.scrollIntoView(scrollOptions);
            } else {
                this.$scrollTo(el);
            }
        },
        /**
         * Focuses and scrolls to the first element with an invalid value.
         * @param {string[]} invalidFieldNames A list of invalid field names.
         */
        focusAndScrollToFirstInvalidField(invalidFieldNames) {
            const el = this.$store.getters[
                'ui/fields/focusAndScrollToOnError/getFirstElementFromInvalidFieldNames'
            ](invalidFieldNames);
            if (el !== false) {
                const focusOptions = {
                    preventScroll: true,
                };
                el.focus(focusOptions);
                this.scrollToElement(el);
            }
        },
    },
};
