/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pv_system_existing_or_planned__interested': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M49.65 22.86h-2.89v-1.71h2.89c.46 0 .84-.38.84-.84s-.38-.84-.84-.84h-2.89v-1.66h-4.34c-1.7 0-3.11 1.45-3.44 3.36h-4.23l-1.26-2.56H22.95l3.82 7.74h10.54l-1.72-3.49h3.4c.33 1.92 1.75 3.36 3.44 3.36h4.34v-1.66h2.89c.46 0 .84-.38.84-.84s-.38-.84-.84-.84zm-4.57 1.66h-2.66c-.99 0-1.83-1.15-1.83-2.52s.84-2.52 1.83-2.52h2.66v5.04zM20.05 18.6H9.51l3.82 7.74h10.54l-3.82-7.74zM18.72 37.26L22.54 45h10.54l-3.82-7.74H18.72zM24.77 28.16H14.23l3.59 7.28h10.55l-3.6-7.28zM41.79 35.44l-3.59-7.28H27.65l3.6 7.28h10.54zM32.15 37.26L35.97 45h10.54l-3.82-7.74H32.15z" _fill="#656666"/>'
  }
})
