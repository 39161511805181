import _ from 'lodash';
import Vue from 'vue';
import { getElementYPosition } from '@/utils/dom';

export default {
    namespaced: true,
    modules: {
        focusAndScrollToOnError: {
            namespaced: true,
            state: [],
            getters: {
                getSorted: state => _.sortBy(state, 'data.yPosition'),
                getFirstElementFromInvalidFieldNames: (state, getters) => (invalidFieldNames) => {
                    const sortedFields = getters.getSorted;
                    for (let index = 0; index < sortedFields.length; index += 1) {
                        const field = sortedFields[index];
                        if (_.includes(invalidFieldNames, field.name)) {
                            return field.el;
                        }
                    }
                    return false;
                },
            },
            mutations: {
                register(state, payload) {
                    const field = {
                        ...payload,
                        data: {
                            ...payload.data,
                            yPosition: getElementYPosition(payload.el),
                        },
                    };
                    let index = 0;
                    for (;index < state.length; index += 1) {
                        if (state[index].name === payload.name) {
                            break;
                        }
                    }
                    Vue.set(state, index, field);
                },
            },
            actions: {
                register({ commit }, payload) {
                    commit('register', payload);
                },
            },
        },
    },
};
