/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_heated_area__101_150': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<g data-name="Gruppe 465"><g data-name="Gruppe 453"><path pid="0" data-name="Pfad 484" d="M47.59 15H13.5v35h35V15zm0 34.09h-15.9V31.86H14.41V15.91h33.18z" _fill="#666"/></g></g>'
  }
})
