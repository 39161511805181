/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'old_heating_energy_consumption__5000_20000': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M30 52.93C17.36 52.93 7.07 42.64 7.07 30S17.35 7.07 30 7.07 52.93 17.36 52.93 30 42.64 52.93 30 52.93zm0-43.38c-11.27 0-20.44 9.17-20.44 20.44S18.73 50.43 30 50.43s20.44-9.17 20.44-20.44S41.27 9.55 30 9.55z" _fill="#666"/><path pid="1" d="M15.4 21.47l3.13 2.2c-.91 1.64-1.46 3.46-1.6 5.33l-3.83.12c.14-2.71.92-5.32 2.29-7.66m-.29-1.42A17.82 17.82 0 0012.07 30v.16l5.81-.19c0-2.43.73-4.68 1.97-6.58l-4.76-3.35zM44.6 21.47c1.37 2.33 2.15 4.94 2.29 7.66l-3.83-.12c-.14-1.87-.69-3.68-1.6-5.33l3.13-2.2m.29-1.42l-4.76 3.35a11.96 11.96 0 011.97 6.58l5.81.19v-.16c0-3.68-1.12-7.11-3.03-9.96zM42.97 31.85l3.82.12c-.32 2.73-1.29 5.32-2.86 7.59l-.63-.49-2.38-1.85a13.05 13.05 0 002.05-5.37m-.9-1.03c-.17 2.48-1.08 4.75-2.53 6.6l3.14 2.44 1.44 1.12c2.17-2.79 3.53-6.23 3.75-9.97l-5.8-.19zM36.52 14.41c2.49 1.04 4.68 2.66 6.42 4.74l-3.12 2.2a13.108 13.108 0 00-4.47-3.3l1.17-3.64m-.63-1.31l-1.78 5.53c2.21.8 4.12 2.23 5.52 4.07l4.75-3.34a17.993 17.993 0 00-8.49-6.26zM30 13.08c1.28 0 2.55.15 3.81.44l-1.17 3.64c-.88-.18-1.76-.28-2.64-.28s-1.76.09-2.64.28l-1.17-3.64c1.26-.29 2.54-.44 3.81-.44m0-1c-1.77 0-3.47.27-5.09.75l1.78 5.53c1.05-.3 2.16-.47 3.3-.47s2.25.17 3.3.47l1.78-5.53c-1.61-.48-3.32-.75-5.09-.75zM23.48 14.41l1.17 3.64c-1.71.77-3.23 1.89-4.47 3.3l-3.12-2.2a16.9 16.9 0 016.42-4.74m.63-1.31c-3.42 1.2-6.37 3.4-8.49 6.26l4.75 3.34c1.4-1.84 3.31-3.27 5.52-4.07l-1.78-5.53zM16.58 36.95c-.22.07-.44.11-.67.11-.29 0-.57-.06-.84-.18-.51-.22-.9-.65-1.09-1.17s-.15-1.1.1-1.59c.24-.48.66-.84 1.17-1.03l2.8-1.2c-.06-.36-.1-.72-.13-1.08l-5.8.19a17.74 17.74 0 003.75 9.97l1.44-1.12 3.14-2.44c-.33-.42-.62-.86-.89-1.32l-2.99.86z" _fill="#666"/><path pid="2" d="M33.27 28.85a3.454 3.454 0 00-4.4-2.09 3.42 3.42 0 00-2.22 2.66l-10.98 4.86c-.43.15-.66.61-.52 1.02.15.41.61.62 1.04.46s11.58-3.18 11.58-3.18c.91.79 2.19 1.09 3.4.66a3.454 3.454 0 002.09-4.4zm-2.7 2.66c-.83.3-1.75-.14-2.05-.97-.3-.83.14-1.75.97-2.05s1.75.14 2.05.97c.3.83-.14 1.75-.97 2.05z" _fill="#666"/>'
  }
})
