/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'water_heating__yes': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M20.63 34.29h3.56v-6.12a1.8 1.8 0 00-1.78-1.78h-3.85V23.8a.73.73 0 00-.72-.73h-.33V22h3.16a.9.9 0 00.89-.89v-.24a.9.9 0 00-.89-.89h-7.36a.89.89 0 00-.89.89v.16a.89.89 0 00.89.89h3.15v1h-.32a.73.73 0 00-.73.72v2.59H11.7V25.1a1.08 1.08 0 00-1.06-1.1h-1v8.17h1a1.06 1.06 0 001-1.05v-1.25h8.94zM23.79 37.44a13.36 13.36 0 01-.89-1.37 3.41 3.41 0 01-.41-.89 1.18 1.18 0 01-.08-.49 1 1 0 01-.08.49 4.75 4.75 0 01-.4.89c-.33.48-.57 1-.89 1.37a4.8 4.8 0 00-.57 1.13 1.89 1.89 0 001.05 2.27 2.77 2.77 0 00.81.16 2.77 2.77 0 00.81-.16 1.85 1.85 0 001.21-2.27 7 7 0 00-.56-1.13zM45.42 42.8a1.56 1.56 0 01-1.38.83H40a1.53 1.53 0 01-1.37-.83L38 41.62c-2.16-.16-4.25-.35-4.84-.39v7.59a1 1 0 001 1H50a1 1 0 001-1v-7.59c-.47 0-2.63.23-5 .39zM34.17 20a1 1 0 00-1 1v19.21H50.98V21a1 1 0 00-1-1zM26 28.5h5V30h-5z" _fill="#666"/><path pid="1" _fill="#666" d="M29.25 26.75v5h-1.5v-5z"/>'
  }
})
