/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'old_heating_energy_consumption__36000_40000': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M30 52.93C17.36 52.93 7.07 42.64 7.07 30S17.35 7.07 30 7.07 52.93 17.36 52.93 30 42.64 52.93 30 52.93zm0-43.38c-11.27 0-20.44 9.17-20.44 20.44S18.73 50.43 30 50.43s20.44-9.17 20.44-20.44S41.27 9.55 30 9.55z" _fill="#666"/><path pid="1" d="M44.6 21.47c1.37 2.33 2.15 4.94 2.29 7.66l-3.83-.12c-.14-1.87-.69-3.68-1.6-5.33l3.13-2.2m.29-1.42l-4.76 3.35a11.96 11.96 0 011.97 6.58l5.81.19v-.16c0-3.68-1.12-7.11-3.03-9.96zM42.97 31.85l3.82.12c-.32 2.73-1.29 5.32-2.86 7.59l-.63-.49-2.38-1.85a13.05 13.05 0 002.05-5.37m-.9-1.03c-.17 2.48-1.08 4.75-2.53 6.6l3.14 2.44 1.44 1.12a17.84 17.84 0 003.75-9.97l-5.8-.19z" _fill="#666"/><g _fill="#666"><path pid="2" d="M19.87 23.39l-4.76-3.35A17.82 17.82 0 0012.08 30v.16l5.81-.19c0-2.43.73-4.68 1.97-6.58zM12.13 31.01a17.74 17.74 0 003.75 9.97l1.44-1.12 3.14-2.44a12.062 12.062 0 01-2.53-6.6l-5.8.19zM30 12.08c-1.77 0-3.47.27-5.09.75l1.78 5.53c1.05-.3 2.16-.47 3.3-.47s2.25.17 3.3.47l1.78-5.53c-1.61-.48-3.32-.75-5.09-.75zM15.62 19.36l4.75 3.34c1.4-1.84 3.31-3.27 5.52-4.07l-1.78-5.53c-3.42 1.2-6.37 3.4-8.49 6.26z"/></g><path pid="3" d="M35.89 13.1l-1.78 5.53c.38.14.74.3 1.1.47l2.03-2.43c.63-.86 1.92-1.07 2.82-.41.43.32.72.78.8 1.32.08.54-.04 1.07-.36 1.52l-1.69 2.64c.29.31.56.62.82.96l4.75-3.34a17.945 17.945 0 00-8.49-6.26z" _fill="#666"/><path pid="4" d="M39.47 18.4c.27-.37.21-.88-.14-1.13-.35-.26-.85-.16-1.12.2s-7.79 9.13-7.79 9.13c-1.19-.14-2.42.33-3.18 1.37a3.447 3.447 0 00.74 4.81c1.53 1.12 3.69.79 4.81-.74.76-1.04.84-2.35.34-3.45l6.35-10.18zm-8.18 12.55c-.52.71-1.53.87-2.24.35s-.87-1.53-.35-2.24 1.53-.87 2.24-.35c.71.52.87 1.53.35 2.24z" _fill="#666"/>'
  }
})
