/**
 * Util to load TNS config.
 * The website exposes on some pages a TNS object (Thermondo NameSpace) via the global "window"
 * object.
 * Some of the configuration elements are targeting the lead-form (window.TNS.config.leadForm.*).
 * This file provides a set of util functions to manage these configuration options.
 */
import { get } from 'lodash';

/**
 * Binds the lead-form configuration object to a constant.
 */
const tnsConfig = () => get(window, 'TNS.config.leadForm');

// eslint-disable-next-line
export { tnsConfig };
