import Vue from 'vue';

export default {
    namespaced: true,
    state: [],
    mutations: {
        add: (state, error) => Vue.set(state, state.length, error),
    },
    actions: {
        add: ({ commit }, error) => commit('add', error),
    },
};
