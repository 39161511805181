/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_year_built__in_construction': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<rect pid="0" x="33.31" y="43.7" width="12.6" height="6.3" rx=".53" ry=".53" _fill="#666"/><rect pid="1" x="19.14" y="43.7" width="12.6" height="6.3" rx=".53" ry=".53" _fill="#666"/><path pid="2" d="M17 43.7h-5.4V50H17a.52.52 0 00.52-.53v-5.25a.52.52 0 00-.52-.52zM24.54 41.6v-5a.66.66 0 00-.65-.66H11.6v6.3h12.29a.65.65 0 00.65-.64zM17.56 34v-5.27a.52.52 0 00-.56-.53h-5.4v6.3H17a.52.52 0 00.56-.5zM30.45 39.43L37 37.94l6.59-1.48a.76.76 0 00.41-1.25l-3.56-3.86-2.74 2.73a1.39 1.39 0 01-1 .41 1.36 1.36 0 01-1-.41 1.36 1.36 0 010-1.95l2.81-2.82-3.72-4a.76.76 0 00-1.28.28l-2 6.45-2 6.45a.76.76 0 00.94.94z" _fill="#666"/><path pid="3" d="M50.21 24.91a1.67 1.67 0 00-.28-.43 1.68 1.68 0 00-2.35 0L44.06 28l.13.13.39.39-1.2 1.2-1.62-1.63a.84.84 0 00-.59-.24.86.86 0 00-.59.24l-4.42 4.43a.82.82 0 000 1.17.81.81 0 001.17 0l3.84-3.84 1.63 1.63a.83.83 0 001.17 0l1.78-1.78.4.39.26.26 3.52-3.52a1.64 1.64 0 00.28-1.92z" _fill="#666"/>'
  }
})
