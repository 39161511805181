import { isMatch } from 'lodash';
import { tnsConfig } from '@/utils/loadTnsConfig';

/**
 * Detect and apply potential behaviour modifications done to the lead-form.
 *
 * To allow marketing department to efficiently provide customers with meaningful content, they can
 * set some specific lead-form behaviour in Wagtail's admin. It will build and expose a "behaviour"
 * configuration object though the "TNS" global object (window.TNS.config.leadForm.modifiers)
 *
 * Note that if Thermondo doesn't operate in the zipcode location that was provided, it will always
 * fallback to a "white-area" configuration, and ignore all behaviour modifiers as they are not
 * relevant anymore.
 *
 * Possibilities:
 *
 * - Custom confirmation page URL:
 *      Possibility to set a custom confirmation page URL, depending on responses given to
 *      questions in the lead-form.
 *
 */
export default {
    methods: {
        applyBehaviourModifiers() {
            if (this.$store.state.settings.isOperatedByThermondo.value) {
                /**
                 * Thermondo does operate in the zipcode location that was provided. We can now
                 * look if any modifier applies.
                 */
                tnsConfig().behaviourModifiers.some(
                    (modifier) => {
                        if (isMatch(this.$store.state.collectedData.answers, modifier.rule)) {
                            this.$store.commit(
                                'settings/confirmationPageUrl/setConfirmationPageUrl',
                                modifier.confirmationPageCustomUrl,
                            );

                            return true;
                        }

                        return false;
                    },
                );
            } else {
                /**
                 * Thermondo doesn't operate in the zipcode location that was provided, we then
                 * fallback to the white-area confirmation page.
                 */
                this.$store.commit(
                    'settings/confirmationPageUrl/setConfirmationPageUrl',
                    tnsConfig().confirmationPageWhiteAreaUrl,
                );
            }
        },
    },
};
