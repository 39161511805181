<template>
    <div class="offer-progress-mobile-circular">
        <svg class="progress-ring"
             height="200"
             width="200">
            <circle class="offer-progress-mobile-circular__progress-bg"
                    :stroke-dasharray="circumference + ' ' + circumference"
                    stroke-width="5"
                    :r="normalizedRadius"
                    cx="100"
                    cy="100" />
            <circle class="offer-progress-mobile-circular__progress"
                    :stroke-dasharray="circumference + ' ' + circumference"
                    :style="{ strokeDashoffset: strokeDashoffset }"
                    stroke-width="5"
                    :r="normalizedRadius"
                    cx="100"
                    cy="100" />
        </svg>
        <div class="offer-progress-mobile-circular__inner">
            <img src="./../assets/offerVisual_mobile.png">
            <div class="offer-progress-mobile-circular__text" data-hj-suppress>
                {{ $store.state.collectedData.customer.salutation }}
                {{ $store.state.collectedData.customer.first_name }}
                {{ $store.state.collectedData.customer.last_name }}
            </div>
            <div class="offer-progress-mobile-circular__percentage">
                {{ percentage }}%
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        const normalizedRadius = 100 - 5 * 2;
        const circumference = normalizedRadius * 2 * Math.PI;

        return {
            normalizedRadius,
            circumference,
        };
    },
    computed: {
        ...mapGetters({
            percentage: 'collectedData/customer/getProgress',
        }),
        strokeDashoffset() {
            return this.circumference - this.percentage / 100 * this.circumference;
        },
    },
};
</script>
