<template>
    <div class="offer-progress-desktop">
        <div class="offer-progress-desktop__box">
            <div class="offer-progress-desktop__fill"
                 :style="{ width: percentage + '%' }">
            </div>
            <div class="offer-progress-desktop__text">
                {{ percentage + text }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            text: '% Ihres persönlichen Angebots',
        };
    },
    computed: mapGetters({
        percentage: 'collectedData/customer/getProgress',
    }),
};
</script>
