<template>
    <modal v-if="show">
        <div slot="header">
            Uuuuups!
        </div>
        <div slot="content">
            <p>
                Es tut uns leid, aber Ihre Anfrage kann zurzeit nicht angenommen werden.
                Unsere Techniker arbeiten daran.
            </p>
            <p>
                Rufen Sie uns in der Zwischenzeit einfach kostenlos an unter
                <a href="tel:08004200300">0800 4 200 300</a>
                (Mo-Fr: 9-18Uhr) oder versuchen Sie es später erneut.
            </p>
            <div class="modal__go-to-homepage-cta-wrapper">
                <a href="https://www.thermondo.de/" class="t-btn-1">
                    Zurück zur Startseite
                </a>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Modal from '@/components/Modal.vue';

export default {
    computed: mapState('ui/modal/error', [
        'show',
    ]),
    methods: mapActions('ui/modal/error', {
        close: 'hide',
    }),
    components: {
        Modal,
    },
};
</script>
