/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_heated_area__201_300': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<g data-name="Gruppe 459"><g data-name="Gruppe 457"><path pid="0" data-name="Pfad 486" d="M46.59 15H12.5v35h35V15zm0 34.09H13.41V15.91h33.18z" _fill="#666"/></g><g data-name="Gruppe 458"><path pid="1" data-name="Rechteck 488" _fill="#666" d="M12.83 24.45h25.22v25.22H12.83z"/></g></g>'
  }
})
