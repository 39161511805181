import Vue from 'vue';

export default {
    namespaced: true,
    state: [],
    mutations: {
        addPreAnsweredEntry(state, entry) {
            if (state.indexOf(entry) <= -1) {
                Vue.set(state, state.length, entry);
            }
        },
    },
};
