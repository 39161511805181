<template>
    <modal v-if="show">
        <div slot="header">
            {{ termsTitle }}
        </div>
        <div slot="content" class="modal-terms__body">
            <div v-if="!termsLoaded && !termsError" class="loader__wrapper">
                <span class="loader"></span>
            </div>
            <div v-if="termsError" class="loader__wrapper">
                Fehler
            </div>
            <div v-html="termsBody"></div>
        </div>
    </modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Modal from '@/components/Modal.vue';
import requestsMixin from '@/mixins/mixin.requests';

export default {
    mixins: [
        requestsMixin,
    ],
    data() {
        return {
            termsError: false,
            termsLoaded: false,
            termsBody: null,
            termsTitle: null,
        };
    },
    computed: mapState('ui/modal/terms', [
        'show',
    ]),
    watch: {
        show() {
            if (!this.termsLoaded) {
                this.loadTerms();
            }
        },
    },
    methods: {
        async loadTerms() {
            const terms = await this.getTerms();
            this.termsBody = terms.body;
            this.termsTitle = terms.title;
            this.termsLoaded = terms.body !== undefined;
            this.termsError = !terms;
        },
        ...mapActions('ui/modal/terms', {
            close: 'hide',
        }),
    },
    components: {
        Modal,
    },
};
</script>
