<template>
    <div class="question__wrapper">
        <div class="question" id="question">
            <transition name="fade" mode="out-in">
                <div :key="step.backend_key" class="question__text">
                    {{ step.label }}
                </div>
            </transition>
            <span @click="showHelp = !showHelp"
                  v-if="step.help"
                  class="question__help-icon">
                <HelpSvg v-if="!showHelp" />
                <CloseSvg v-else />
            </span>
        </div>
        <help-text :show="showHelp" :text="step.help"></help-text>
    </div>
</template>

<script>
import HelpText from '@/components/HelpText.vue';
import HelpSvg from '../../public/svg/help.svg';
import CloseSvg from '../../public/svg/close.svg';

export default {
    data() {
        return {
            step: '',
            showHelp: false,
        };
    },
    created() {
        this.init();
    },
    components: {
        HelpSvg,
        CloseSvg,
        HelpText,
    },
    methods: {
        init() {
            this.step = this.$root.appConfig.steps[this.$route.params.id];
            this.showHelp = false;
        },
    },
    watch: {
        $route: {
            handler() {
                this.init();
            },
            deep: true,
        },
    },
};
</script>
