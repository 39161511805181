<template>
    <div>
        <button class="t-btn-1 controls__button controls__button--next"
                :class="{ 't-btn-is-loading': loading || redirecting }"
                type="submit"
                v-if="canNavigateNextStep"
                @click="goNext"
                :disabled="loading || redirecting"
                tabindex="99">
            <span></span>
            {{ ctaLabel }}
        </button>
    </div>
</template>

<script>
import controlsMixin from '@/mixins/mixin.controls';

export default {
    inject: ['$validator'],
    mixins: [controlsMixin],
};
</script>
