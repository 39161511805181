import Vue from 'vue';
import fields from './fields';
import modal from './modal';

export default {
    namespaced: true,
    state: {
        loading: false,
        preventSubmit: false,
        redirecting: false,
        transition: false,
        ctaLabel: 'Weiter',
        showErrorInfoBox: false,
        canNavigateNextStep: false,
    },
    mutations: {
        setLoading(state, status) {
            Vue.set(state, 'loading', status);
        },
        setPreventSubmit(state, status) {
            Vue.set(state, 'preventSubmit', status);
        },
        setRedirecting(state, status) {
            Vue.set(state, 'redirecting', status);
        },
        setTransition(state, status) {
            Vue.set(state, 'transition', status);
        },
        setCtaLabel(state, status) {
            Vue.set(state, 'ctaLabel', status || 'Weiter');
        },
        setShowErrorInfoBox(state, status) {
            Vue.set(state, 'showErrorInfoBox', status);
        },
        setCanNavigateNextStep(state, status) {
            Vue.set(state, 'canNavigateNextStep', status);
        },
    },
    actions: {
        setLoading({ commit }, status) {
            commit('setLoading', status);
        },
        setPreventSubmit({ commit }, status) {
            commit('setPreventSubmit', status);
        },
        setRedirecting({ commit }, status) {
            commit('setRedirecting', status);
        },
        setTransition({ commit }, status) {
            commit('setTransition', status);
        },
        setCtaLabel({ commit }, status) {
            commit('setCtaLabel', status);
        },
        setShowErrorInfoBox({ commit }, status) {
            commit('setShowErrorInfoBox', status);
        },
        setCanNavigateNextStep({ commit }, status) {
            commit('setCanNavigateNextStep', status);
        },
    },
    modules: {
        fields,
        modal,
    },
};
