import Vue from 'vue';
import { eT } from '@/utils/eventTracking';

export default {
    namespaced: true,
    modules: {
        error: {
            namespaced: true,
            state: {
                show: false,
            },
            mutations: {
                show(state) {
                    Vue.set(state, 'show', true);

                    eT({
                        event: 'error_overlay',
                    });
                },
                hide(state) {
                    Vue.set(state, 'show', false);
                },
            },
            actions: {
                show: ({ commit }) => commit('show'),
                hide: ({ commit }) => commit('hide'),
            },
        },
        terms: {
            namespaced: true,
            state: {
                show: false,
            },
            mutations: {
                show(state) {
                    Vue.set(state, 'show', true);
                },
                hide(state) {
                    Vue.set(state, 'show', false);
                },
            },
            actions: {
                show: ({ commit }) => commit('show'),
                hide: ({ commit }) => commit('hide'),
            },
        },
    },
};
