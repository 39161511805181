import Vue from 'vue';
import Router from 'vue-router';
import Loader from '@/components/Loader.vue';
import store from '@/store';

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: '/:id',
            component: Loader,
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (store.state.ui.modal.error.show) {
        /**
         * Freezes the router when the modalError overlay is shown.
         */
        next(false);
    } else {
        next();
    }
});

export default router;
