/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heat_distribution__radiator': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<g data-name="Gruppe 604"><g data-name="Gruppe 521" _fill="#676767"><path pid="0" data-name="Pfad 650" d="M23.58 25.89a.73.73 0 00-.73.73v9.73h-.37v.92h.36v.8a.73.73 0 00.73.72.73.73 0 00.73-.72V26.63a.74.74 0 00-.72-.74z"/><path pid="1" data-name="Pfad 651" d="M25.59 25.89a.73.73 0 00-.72.73v11.45a.73.73 0 00.74.71.73.73 0 00.71-.71V26.63a.73.73 0 00-.72-.74z"/><path pid="2" data-name="Pfad 652" d="M27.62 25.89a.74.74 0 00-.73.73v11.45a.73.73 0 001.46 0V26.63a.73.73 0 00-.72-.74z"/><path pid="3" data-name="Pfad 653" d="M29.65 25.89a.73.73 0 00-.73.73v11.45a.73.73 0 101.45 0V26.63a.72.72 0 00-.72-.74z"/><path pid="4" data-name="Pfad 654" d="M31.67 25.89a.73.73 0 00-.72.73v11.45a.73.73 0 101.45 0V26.63a.74.74 0 00-.73-.74z"/><path pid="5" data-name="Pfad 655" d="M33.7 25.89a.73.73 0 00-.73.73v11.45a.73.73 0 001.46 0V26.63a.74.74 0 00-.73-.74z"/><path pid="6" data-name="Pfad 656" d="M36.8 27.7v.3h-.35v-1.37a.73.73 0 10-1.45 0v11.44a.73.73 0 101.45 0v-.83h.35v-.91h-.35v-7.62h.35V29l1.5-.14v-1z"/></g></g><path pid="7" _fill="#666" d="M14.78 40.01h29.41v2.26H14.78zM29.75 14.62l13.8 10.47 2.2-2.9-16-12.14-16 12.14 2.2 2.9 13.8-10.47z"/><path pid="8" _fill="#666" d="M18.72 21.17H18.26v19.47H18.76V21.17h-.04zM41.2 21.17H40.74v19.47H41.24V21.17h-.04z"/>'
  }
})
