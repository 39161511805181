import _ from 'lodash';
import appConfig from '@/config/app.json';
import requestsMixin from '@/mixins/mixin.requests';
import {
    getRouteFromStepKey,
    getStepKeyFromRoute,
    getNextStepPathFromNextStepType,
    getEntryRoute,
} from '../utils/appConfigGetters';

export default {
    mixins: [requestsMixin],
    methods: {
        /**
         * Returns the previous question path from history, if available.
         * @returns {String}
         */
        getPreviousStepPath() {
            return getRouteFromStepKey(
                this.$store.state.status.progress[
                    this.$store.state.status.progress.length - 2
                ],
            );
        },

        /**
         * Returns the next step path of the current path (from the $route) or a given one.
         * @param {string} [choice] - Question choice.
         * @param {string} [fromPath] - Path from which the next step path will be taken.
         * @returns {String}
         */
        getNextStepPath({ choice, fromPath } = {}) {
            const path = fromPath || this.$route.params.id;

            /**
             * When a user navigates backward to a question that has already been answered and then
             * navigate forward using the "Weiter" button (which by definition doesn't pass a
             * choice), we want to retrieve the choice already made.
             * The reason why it's important to be aware of the choice at that point is because
             * it might have a "next_step" entry, which should be used for navigating forward.
             * Reported in Jira LF-146.
             */
            if (!choice) {
                // eslint-disable-next-line no-param-reassign
                choice = this.getChoiceFromCurrentStep();
            }

            /*
             * There can be two main types of "next_step" in the appConfig.
             *
             * 1. On the question level:
             * This is the default, every questions have one, otherwise we could just not move
             * forward.
             *
             * 2. On the choice level:
             * Useful for edge cases, when it is for example required to show a specific
             * question based on the chosen answer.
             */


            /*
             * It defaults to the question-level "next_step".
             */
            let nextStep = appConfig.steps[path].next_step;

            /*
             * Check if the current choice object has its own "next_step", and use it instead if so.
             */
            if (choice) {
                const { choices } = appConfig.steps[path];
                const choiceObj = _.find(choices, ['value', choice]);

                if (Object.prototype.hasOwnProperty.call(
                    choiceObj,
                    'next_step',
                )) {
                    nextStep = choiceObj.next_step;
                }
            }

            const nextStepPath = getNextStepPathFromNextStepType(
                nextStep,
                this.$store.state.collectedData.answers,
            );

            /**
             * Check if "nextStepPath" has already been answered. If so, go the the next step.
             */
            if (_.includes(
                this.$store.state.status.preAnswered,
                getStepKeyFromRoute(nextStepPath),
            )) {
                return this.getNextStepPath({
                    fromPath: nextStepPath,
                    choice: this.$store.state.collectedData
                        .answers[getStepKeyFromRoute(nextStepPath)],
                });
            }

            return nextStepPath;
        },
        navigatePrevStep() {
            this.$router.back();
        },
        navigateNextStep(choice) {
            if (this.$route.params.id === getRouteFromStepKey('zipcode')) {
                this.validate(this.validateZipcode);
                return;
            }

            if (this.$route.params.id === getRouteFromStepKey('inquire')) {
                this.validate(this.postToBackend);
                return;
            }

            this.$router.push({
                path: this.getNextStepPath({ choice }),
            });
        },

        /**
         * Retrieves the choice of the current step.
         * @returns {String}
         */
        getChoiceFromCurrentStep() {
            return Object.prototype.hasOwnProperty.call(
                this.$store.state.collectedData.answers,
                getStepKeyFromRoute(this.$route.params.id),
            )
                ? this.$store.state.collectedData.answers[
                    getStepKeyFromRoute(this.$route.params.id)
                ]
                : undefined;
        },

        /**
         * Retrieves the application starting path.
         * If no questions have been pre-answered, it defaults to the app.json's "entry" value,
         * otherwise it will traverse the app.json's tree looking for the first non-answered
         * question.
         * @returns {string}
         */
        getStartingPath() {
            return _.includes(
                this.$store.state.status.preAnswered,
                getStepKeyFromRoute(getEntryRoute()),
            )
                ? this.getNextStepPath({
                    fromPath: getEntryRoute(),
                    choice: this.$store.state.collectedData.answers[
                        getStepKeyFromRoute(getEntryRoute())
                    ],
                })
                : getEntryRoute();
        },

        checkCanNavigateNextStep() {
            const { step_key: stepKey } = appConfig.steps[this.$route.params.id];
            const currentStep = getRouteFromStepKey(stepKey);
            const { cta_label: ctaLabel } = appConfig.steps[currentStep];

            this.$store.dispatch('ui/setCtaLabel', ctaLabel);
            /**
             * On some pages, we need the Weiter button to always be shown.
             */
            if (
                this.$route.params.id === getRouteFromStepKey('zipcode')
                || this.$route.params.id === getRouteFromStepKey('inquire')
            ) {
                return true;
            }

            /**
             * This avoid showing the Weiter button for a few seconds when clicking on a choice,
             * during the transition.
             */
            if (this.$store.state.ui.transition) {
                return false;
            }

            /**
             * We finally check if the current question has already been answered, and show the
             * weiter button if so.
             */
            return Object.prototype.hasOwnProperty.call(
                this.$store.state.collectedData.answers,
                this.$store.state.status.progress[
                    this.$store.state.status.progress.length - 1
                ],
            );
        },

        beforeEnterTransition() {
            this.$store.dispatch('ui/setCanNavigateNextStep', this.checkCanNavigateNextStep());
        },
    },
    computed: {
        canNavigatePrevStep() {
            return typeof this.getPreviousStepPath() === 'string';
        },
        canNavigateNextStep() {
            /**
             * On some pages, we need the Weiter button to always be shown.
             */
            if (
                this.$route.params.id === getRouteFromStepKey('zipcode')
                || this.$route.params.id === getRouteFromStepKey('inquire')
            ) {
                return true;
            }

            /**
             * This avoid showing the Weiter button for a few seconds when clicking on a choice,
             * during the transition.
             */
            if (this.$store.state.ui.transition) {
                return false;
            }

            /**
             * We finally check if the current question has already been answered, and show the
             * weiter button if so.
             */
            return Object.prototype.hasOwnProperty.call(
                this.$store.state.collectedData.answers,
                this.$store.state.status.progress[
                    this.$store.state.status.progress.length - 1
                ],
            );
        },
    },
};
