import { sentryClient } from '@/utils/sentryClient';
import appConfig from '@/config/app.json';
import store from '@/store';

const eventObjectBuilder = (eventObject) => {
    if (eventObject === undefined) {
        sentryClient.captureMessage('Missing tracking argument', {
            level: 'error',
        });
        return;
    }
    if (typeof eventObject !== 'object') {
        sentryClient.captureMessage('Invalid tracking argument', {
            level: 'error',
            extra: {
                argument: eventObject,
            },
        });
        return;
    }

    const output = {
        session_id: store.state.collectedData.tracking.session_id,
        event_order: store.state.collectedData.tracking.event_order,
        build_id: appConfig.build_id,
        timestamp: Date.now(),
    };

    Object.keys(eventObject).forEach((key) => {
        output[key] = eventObject[key];
    });

    // eslint-disable-next-line
    return output;
};

/**
 * Function taking care of feeding the data layer on demand.
 * Takes an array as argument, like the following:
 * [eventCategory, eventAction, eventLabel, eventValue]
 * Note that eventValue is optional.
 * @param eventObject {object}
 */
const eventTracking = (eventObject) => {
    try {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(eventObjectBuilder(eventObject));
        store.dispatch('collectedData/tracking/incrementEventOrder');
    } catch (error) {
        sentryClient.captureException(error, {
            level: 'warning',
        });
    }
};

// eslint-disable-next-line
export { eventTracking as eT };
