/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'people_count__1_2': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<g data-name="Gruppe 492" _fill="#666"><path pid="0" data-name="Pfad 568" d="M27.45 30.81a3.14 3.14 0 00-.92-2.22 2.77 2.77 0 00-.73-.54 2.71 2.71 0 00-.86-.32 4.48 4.48 0 00-.64-.07 3.23 3.23 0 00-1.65.48 3.73 3.73 0 00-.76.67 1.5 1.5 0 00-.19.25 3.07 3.07 0 00-.55 1.75 3 3 0 00.39 1.5 3.07 3.07 0 00.76.92 3.13 3.13 0 004.42-.39 3.09 3.09 0 00.73-2z"/><path pid="1" data-name="Pfad 569" d="M27.2 35.68s-.07 0-.1-.06a4.53 4.53 0 00-.63-.45 4.71 4.71 0 00-2.2-.57 4.43 4.43 0 00-2.57.79 4.56 4.56 0 00-2 3.82v8.24h7v-9.06a8.38 8.38 0 01.5-2.71z"/><circle pid="2" data-name="Ellipse 3" cx="33.85" cy="26.45" r="4.45"/><path pid="3" data-name="Pfad 570" d="M28 35.52c-.1.23-.2.42-.29.64a6.29 6.29 0 00-.41 2.23V50h13V38.39A6.5 6.5 0 0028 35.52z"/></g>'
  }
})
