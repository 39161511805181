import {
    BrowserClient,
    defaultStackParser,
    getDefaultIntegrations,
    makeFetchTransport,
} from '@sentry/browser';
import appConfig from '../config/app.json';

/**
 * To avoid conflicts with the website's sentry instance, we need to bind Sentry to a separate
 * client.
 * More info: https://docs.sentry.io/platforms/javascript/guides/vue/troubleshooting/#using-a-client-directly
 *
 * Please note that in January 2023, there is still no support for the VueJS Sentry integration when
 * using multiple instances. We can only use @sentry/browser for now, but things might change.
 * TODO: Use Vue Sentry integration when possible.
 */
const sentryClient = new BrowserClient({
    dsn: 'https://2dd0af2b511342708ccff9a68615d3f7@sentry.io/289349',
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    release: appConfig.build_id,
    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    integrations: getDefaultIntegrations({}),
});

// eslint-disable-next-line
export { sentryClient };
