import Vue from 'vue';
import { sentryClient } from '@/utils/sentryClient';

export default {
    namespaced: true,
    modules: {
        confirmationPageUrl: {
            namespaced: true,
            state: {
                value: null,
            },
            mutations: {
                setConfirmationPageUrl: (state, value) => {
                    if (typeof value === 'string') {
                        Vue.set(state, 'value', value);
                    } else {
                        sentryClient.captureMessage('value may only be set to a string', {
                            level: 'error',
                            extra: {
                                value,
                            },
                        });
                    }
                },
            },
            actions: {
                setConfirmationPageUrl: ({ commit }, value) => commit('setConfirmationPageUrl', value),
            },
        },
        token: {
            namespaced: true,
            state: {
                value: process.env.VUE_APP_BACKEND_API_TOKEN,
            },
            mutations: {
                setToken: (state, value) => {
                    if (typeof value === 'string') {
                        Vue.set(state, 'value', value);
                    } else {
                        sentryClient.captureMessage('value may only be set to a string', {
                            level: 'error',
                            extra: {
                                value,
                            },
                        });
                    }
                },
            },
            actions: {
                setToken: ({ commit }, value) => commit('setToken', value),
            },
        },
        referrerId: {
            namespaced: true,
            state: {
                value: null,
            },
            mutations: {
                setReferrerId: (state, value) => {
                    if (typeof value === 'string') {
                        Vue.set(state, 'value', value);
                    }
                },
            },
            actions: {
                setReferrerId: ({ commit }, value) => commit('setReferrerId', value),
            },
        },
        isOperatedByThermondo: {
            namespaced: true,
            state: {
                value: true,
            },
            mutations: {
                setIsOperatedByThermondo: (state, value) => {
                    Vue.set(state, 'value', value);
                },
            },
            actions: {
                setIsOperatedByThermondo: ({ commit }, value) => commit(
                    'setIsOperatedByThermondo',
                    value,
                ),
            },
        },
    },
};
