import { sentryClient } from '@/utils/sentryClient';
import { sessionStorageEnabled } from '@/utils/checkSessionStorage';

const AB_TEST_WINDOW_KEY = 'ab_tests';

const getParsedIds = (item) => {
    let { campaignId, variationId } = item;

    if (typeof campaignId === 'string') {
        campaignId = parseInt(campaignId, 10);
    }

    if (typeof variationId === 'string') {
        variationId = parseInt(variationId, 10);
    }

    return {
        campaignId,
        variationId,
    };
};

/**
 * Check if the variation is active.
 * @param ticket: {string} - The ticket like `mt2994`
 * @param variation: {number} - The variation number like `1`
 * @returns {boolean}
 */
export const isVariationActive = (ticket, variation = 1) => window[AB_TEST_WINDOW_KEY].some(
    item => item?.ticket === ticket && item?.variation === variation,
);

export const getVariation = (ticket) => {
    const item = window[AB_TEST_WINDOW_KEY].find(i => i?.ticket === ticket);
    return item?.variation || 1;
};

const pushAbTest = (item) => {
    if (typeof item === 'string' && !isVariationActive(item)) {
        Array.prototype.push.call(window[AB_TEST_WINDOW_KEY], { ticket: item, variation: 1 });
    }
    // if object replace if existing or push if new
    if (typeof item === 'object') {
        const { ticket } = item;
        const index = window[AB_TEST_WINDOW_KEY].findIndex(i => i?.ticket === ticket);
        if (index > -1) {
            window[AB_TEST_WINDOW_KEY][index] = item;
        } else {
            Array.prototype.push.call(window[AB_TEST_WINDOW_KEY], item);
        }
    }
};

/**
 * Initialize the AB tests.
 *
 * Usage in VWO:
 * ```js
 * window.ab_tests = window.ab_tests || [];
 * window.ab_tests.push('mt2994');
 * OR
 * window.ab_tests.push({ ticket: 'mt2994', variation: 1 });
 * ```
 */
export const initAbTests = () => {
    window[AB_TEST_WINDOW_KEY] = window[AB_TEST_WINDOW_KEY] || [];
    window[AB_TEST_WINDOW_KEY] = window[AB_TEST_WINDOW_KEY].map(pushAbTest);
    window[AB_TEST_WINDOW_KEY].push = pushAbTest;
};

export const getAbTestData = () => {
    if (!sessionStorageEnabled()) {
        return undefined;
    }

    const session = sessionStorage.getItem('ab_test_session');
    if (!session) {
        return undefined;
    }

    const campaignIds = [];
    const variationIds = [];

    try {
        const data = JSON.parse(session);
        data.forEach((item) => {
            const { campaignId, variationId } = getParsedIds(item);
            if (typeof campaignId === 'number' && typeof variationId === 'number') {
                campaignIds.push(campaignId);
                variationIds.push(variationId);
            }
        });
    } catch (e) {
        sentryClient.captureException(e);
    }

    if (campaignIds.length > 0 && variationIds.length > 0) {
        return {
            vwo: {
                campaign_ids: campaignIds,
                variation_ids: variationIds,
            },
        };
    }

    return undefined;
};
