/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'energy_source_old__none_yet': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M29.79 14.88A17.71 17.71 0 1047.5 32.59a17.74 17.74 0 00-17.71-17.71zM44.5 32.59a14.64 14.64 0 01-3 8.83L21 20.86a14.68 14.68 0 0123.5 11.73zm-29.42 0a14.63 14.63 0 013.68-9.69l20.73 20.72a14.69 14.69 0 01-24.41-11z" _fill="#666"/>'
  }
})
