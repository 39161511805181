/**
 * POST redirect.
 *
 * Sometimes, a redirect is needed. For example, when a lead is submitted, we redirect to a
 * confirmation page on the website. Now, if we need to pass data to this page, what are our
 * options? Adding query parameters to the url works fine, but what if the data is not GDPR-
 * compliant? It would appear in the website logs, which is not what we want.
 *
 * Another approach is to build a hidden form with JS and programmatically submit it, which allows
 * to to a POST to the target page.
 *
 * @param {String} redirectToUrl - URL to redirect to.
 * @param {Object} params - An object containing the CSRF token if posting against the website,
 * as well as the data to be posted.
 */
const postRedirect = (redirectToUrl, params = {}) => {
    // If no CSRF token cookie is present, do a simple redirect.
    if (!params.csrfmiddlewaretoken) {
        window.location.assign(redirectToUrl);
        return;
    }

    // Otherwise, build a form and submit it with POST data.
    const redirectForm = document.createElement('form');
    redirectForm.setAttribute('method', 'POST');
    redirectForm.setAttribute('action', redirectToUrl);

    Object.keys(params).forEach((key) => {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('value', params[key]);
        redirectForm.appendChild(hiddenField);
    });

    document.body.appendChild(redirectForm);
    redirectForm.submit();
};

// eslint-disable-next-line
export { postRedirect };
