/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'old_heating_energy_consumption__41000_45000': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M30 53.97c-12.64 0-22.93-10.29-22.93-22.93S17.35 8.11 30 8.11 52.93 18.4 52.93 31.04 42.64 53.97 30 53.97zm0-43.38c-11.27 0-20.44 9.17-20.44 20.44S18.73 51.47 30 51.47s20.44-9.17 20.44-20.44S41.27 10.59 30 10.59z" _fill="#666"/><path pid="1" d="M42.97 32.89l3.82.12c-.32 2.73-1.29 5.32-2.86 7.59l-.63-.49-2.38-1.85a13.05 13.05 0 002.05-5.37m-.9-1.03c-.17 2.48-1.08 4.75-2.53 6.6l3.14 2.44 1.44 1.12c2.17-2.79 3.53-6.23 3.75-9.97l-5.8-.19z" _fill="#666"/><g _fill="#666"><path pid="2" d="M19.87 24.43l-4.76-3.35a17.82 17.82 0 00-3.03 9.96v.16l5.81-.19c0-2.43.73-4.68 1.97-6.58zM17.93 31.86l-5.8.19a17.74 17.74 0 003.75 9.97l1.44-1.12 3.14-2.44a12.062 12.062 0 01-2.53-6.6z"/></g><g _fill="#666"><path pid="3" d="M35.89 14.14l-1.78 5.53c2.21.8 4.12 2.23 5.52 4.07l4.75-3.34a17.993 17.993 0 00-8.49-6.26zM30 13.12c-1.77 0-3.47.27-5.09.75l1.78 5.53c1.05-.3 2.16-.47 3.3-.47s2.25.17 3.3.47l1.78-5.53c-1.61-.48-3.32-.75-5.09-.75zM15.62 20.4l4.75 3.34c1.4-1.84 3.31-3.27 5.52-4.07l-1.78-5.53c-3.42 1.2-6.37 3.4-8.49 6.26z"/></g><path pid="4" d="M44.89 21.08l-4.76 3.35c.21.32.4.65.58.99l3.07-.73c1.02-.31 2.18.29 2.51 1.36.16.53.1 1.1-.17 1.58-.25.45-.66.79-1.16.95l-2.93 1.1c.05.43.08.87.08 1.32l5.81.19v-.16c0-3.68-1.12-7.11-3.03-9.96z" _fill="#666"/><path pid="5" d="M45.09 26.41c-.13-.41-.58-.64-1.02-.51s-11.71 2.66-11.71 2.66c-.87-.83-2.14-1.19-3.37-.81a3.437 3.437 0 00-2.28 4.3 3.437 3.437 0 004.3 2.28 3.444 3.444 0 002.34-2.56l11.18-4.36c.44-.13.69-.58.56-.99zm-14.62 6.16c-.85.26-1.74-.22-2-1.06-.26-.85.22-1.74 1.06-2 .85-.26 1.74.22 2 1.06.26.85-.22 1.74-1.06 2z" _fill="#666"/>'
  }
})
