/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'building_type__detached_house': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<path pid="0" _fill="#666" d="M16.74 47.21h26.1v2.78h-26.1zM42.52 33.54L30 24.04l-12.53 9.5-2-2.63L30 19.89l14.53 11.02-2.01 2.63z"/><path pid="1" d="M30 24.82l-10.41 7.9v14h7.21v-8.1h6.39v8.13h7.21v-14zm-.48 11.69h-2.66v-2.66h2.66zm0-3.5h-2.66v-2.66h2.66zm3.62 3.5h-2.66v-2.66h2.66zm0-3.5h-2.66v-2.66h2.66z" _fill="#666"/>'
  }
})
