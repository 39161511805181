import _ from 'lodash';
import { eT } from '@/utils/eventTracking';
import requestsUtil from '@/utils/requests';

export default {
    methods: {
        /**
         * Pushes an event to the `dataLayer` when a request fails with information about the error.
         */
        async trackError(error) {
            eT({
                event: 'lf_error',
                error_message: error.message,
                error_response: JSON.stringify(_.get(error, 'response.data')) || 'none',
            });
        },
        /**
         * Executes operations that are common when handling of some or all request failures.
         * @param  {Object} error - The error the request threw.
         * @param  {Object} [errorData] - Data specifying which operations should be executed.
         * The modalError.vue will never be shown if this value is false.
         * Will not show the modalError.vue if the request's response status code is in this array.
         */
        async handleErrorCommon(error, errorData) {
            const fullErrorData = requestsUtil.getFullErrorData(errorData);
            this.$store.dispatch('status/httpErrors/add', error);

            if (fullErrorData.track) {
                this.trackError(error);
            }

            if (
                !requestsUtil.isExpectedResponse(error, fullErrorData)
                && fullErrorData.showModal === true
            ) {
                await this.$store.dispatch('ui/modal/error/show');
            }
        },
    },
};
