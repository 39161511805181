<template>
    <transition name="modal">
        <div class="modal__overlay" tabindex="-1">
            <div class="modal__container" role="dialog" aria-modal="true">
                <button class="modal__close-btn"
                        aria-label="Close modal"
                        @click="$parent.close">
                    <CloseSvg />
                </button>
                <header class="modal__header">
                    <slot name="header"> </slot>
                </header>
                <main class="modal__content">
                    <slot name="content"> </slot>
                </main>
            </div>
        </div>
    </transition>
</template>

<script>
import CloseSvg from '../../public/svg/close.svg';

export default {
    components: {
        CloseSvg,
    },
};
</script>
